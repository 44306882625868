// @flow
import React, { useReducer } from "react";
import StoreContext from "./context/store";
// import type { ActionType as AnalyticsActionType } from "./types/analytics";
// import type { ActionType as LocationsActionType } from "./types/locations";
// import type { ActionType as ProductLocationsActionType } from "./types/product-locations";
// import type { ActionType as SectionActionType } from "./types/section";
// import type { ActionType as UserActionType } from "./types/user";

// type Props = {
//     children: Node,
//     initialValue?: any,
//     rootReducer: (
//         any,
//         {
//             type:
//                 | AnalyticsActionType
//                 | LocationsActionType
//                 | ProductLocationsActionType
//                 | SectionActionType
//                 | UserActionType
//                 | "__INIT__",
//         },
//     ) => mixed,
// };

type Props = any;

const Store = (props: Props) => {
    const initialState = props.rootReducer(props.initialValue, { type: "__INIT__" });
    const [state, dispatch] = useReducer(props.rootReducer, initialState);
    return (
        <StoreContext.Provider value={[state, dispatch]}>{props.children}</StoreContext.Provider>
    );
};

export default Store;
