// @flow
import React from "react";
import Styles from "./index.module.scss";
import Box from "../../components/box";
import logo from "../../assets/FSC-logo.png";

const UnVerifiedEmail = () => {
    return (
        <div className={`${Styles.register} box_wrapper`}>
            <div className="logo-wrapper">
                <img src={logo} className="logo" alt="Forest Stewardship Council" />
                <span className="logo-site-text">
                    Supplier
                    <br />
                    Portal
                </span>
            </div>
            <Box className={Styles.register_box}>
                <h1>Activate Your Account</h1>

                <div className={Styles["unverified"]}>
                    <p>
                        An account activation message has been sent to your email address. If you
                        don't see it within 10 minutes, please check your junk/spam folder.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Once you've confirmed your account, you'll be able to continue creating your
                        profile.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        If you are still having trouble please contact FSC-US at{" "}
                        <a href={"mailto:info@us.fsc.org"}>info@us.fsc.org</a>.
                    </p>
                </div>
            </Box>
        </div>
    );
};

export default UnVerifiedEmail;
