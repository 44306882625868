// @flow
import React from "react";
import Styles from "./index.module.scss";

type Props = {
    label: string,
    onClick: (e: SyntheticEvent<HTMLElement>) => any,
    styles?: { [string]: string },
    disabled?: boolean,
    fullWidth?: boolean,
    gray?: boolean,
    outline?: boolean,
    remove?: boolean,
};

const Button = (props: Props) => {
    const { label, onClick, styles, fullWidth, gray, outline, remove, disabled, ...rest } = props;
    return (
        <button
            {...rest}
            className={`${Styles.button} ${fullWidth ? Styles.fullWidth : ""} ${
                gray ? Styles.gray : ""
            } ${outline ? Styles.outline : ""} ${remove ? Styles.remove : ""} ${
                disabled ? Styles["disabled-button"] : ""
            }`}
            disabled={disabled}
            onClick={onClick}
        >
            {label}
        </button>
    );
};

export default Button;
