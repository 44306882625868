// @flow
import React, { useContext, useEffect } from "react";
import Styles from "./index.module.scss";
import { getAnalytics } from "../../../state/actions/analytics";
import StoreContext from "../../../state/context/store";

type Props = {};

const Analytics = (props: Props) => {
    const [store, dispatch] = useContext(StoreContext);
    const { analytics } = store.analyticsReducer;
    const { productLocations } = store.productLocationsReducer;

    useEffect(() => {
        getAnalytics(dispatch);
    }, [dispatch]);

    console.log(store.productLocationsReducer.productLocations);
    return (
        <div className={Styles.analytics}>
            <h1>Analytics</h1>

            {analytics && (
                <>
                    <span className={Styles["updated-date"]}>
                        Updated on {analytics.date}
                    </span>
                    <ul className={Styles["analytics-boxes"]}>
                        <li>
                            <h2>Views</h2>
                            <p>{analytics.views}</p>
                        </li>
                        <li>
                            <h2>Unique Visitors</h2>
                            <p>{analytics.uniqueVisitors}</p>
                        </li>
                        <li>
                            <h2>Unique Sessions</h2>
                            <p>{analytics.uniqueSessions}</p>
                        </li>
                        {analytics.affiliates &&
                            analytics.affiliates.length > 0 && (
                                <li>
                                    <h2>Affiliates</h2>
                                    <ul>
                                        {analytics.affiliates.map(
                                            (affiliate) => {
                                                if (affiliate.website !== "") {
                                                    return (
                                                        <li
                                                            key={affiliate.name}
                                                        >
                                                            <a
                                                                href={
                                                                    affiliate.website
                                                                }
                                                                target={
                                                                    "_blank"
                                                                }
                                                            >
                                                                {affiliate.name}
                                                            </a>
                                                        </li>
                                                    );
                                                } else {
                                                    return (
                                                        <li
                                                            key={affiliate.name}
                                                        >
                                                            {affiliate.name}
                                                        </li>
                                                    );
                                                }
                                            }
                                        )}
                                    </ul>
                                </li>
                            )}
                    </ul>
                    <div className={Styles["analytics-charts"]}>
                        {analytics.audienceRegionUs && (
                            <>
                                <h2>Audience - Us Region</h2>
                                <img
                                    src={analytics.audienceRegionUs}
                                    width={"100%"}
                                    alt={"Audience - Us Region"}
                                />
                            </>
                        )}
                        {analytics.audienceCountry && (
                            <>
                                <h2>Audience - Country</h2>
                                <img
                                    src={analytics.audienceCountry}
                                    width={"100%"}
                                    alt={"Audience - Country"}
                                />
                            </>
                        )}
                        {analytics.userSessionGraph && (
                            <>
                                <h2>
                                    User, Session and Bounce Rates over time
                                </h2>
                                <img
                                    className={Styles["user-session-bounce"]}
                                    src={analytics.userSessionGraph}
                                    width={"100%"}
                                    alt={
                                        "User, Session and Bounce Rates over time"
                                    }
                                />
                            </>
                        )}
                        {analytics.sessionByDevice && (
                            <>
                                <h2>Sessions by Device</h2>
                                <img
                                    className={Styles["by-device"]}
                                    src={analytics.sessionByDevice}
                                    width={"100%"}
                                    alt={"Sessions by Device"}
                                />
                            </>
                        )}
                    </div>
                </>
            )}
            {!analytics && <h4>Waiting for Analytics info.</h4>}

            {/* <table>
                <thead>
                    <tr>
                        <th>Location</th>
                        <th>Product</th>
                        <th>Searches</th>
                        <th>Average Rank</th>
                    </tr>
                </thead>
                <tbody>
                    {productLocations.map((pl) => (
                        <tr key={pl.id}>
                            <td>{pl.locationDisplay}</td>
                            <td>{pl.productName}</td>
                            <td>{pl.totalSearchCount}</td>
                            <td>{pl.averageSearchRank}</td>
                        </tr>
                    ))}
                </tbody>
            </table> */}
        </div>
    );
};

export default Analytics;
