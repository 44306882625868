// @flow
import type {
    ActionType,
    LocationsContextType,
    LocationsType,
    LocationType,
} from "../types/locations";

const initialState: LocationsContextType = {
    locations: [],
};

const deleteLocation = (
    locations: LocationsType,
    location: LocationType
): LocationsType => {
    const idx = locations.findIndex(
        newLocation => newLocation.id === location.id
    );
    locations.splice(idx, 1);
    return [...locations];
};

const updateLocations = (
    locations: LocationsType,
    location: LocationType
): LocationsType => {
    const newLocations: LocationsType = [...locations];
    const {
        id,
        city,
        country,
        postalCode,
        species,
        streetAddress1,
        streetAddress2,
        locationTypes,
        latitude,
        longitude,
        salesContactName,
        salesContactPhone,
        salesContactEmail,
        sameSalesContact,
        title,
    } = location;

    const idx = newLocations.findIndex(newLocation => newLocation.id === id);

    if (idx > -1) {
        newLocations[idx] = {
            ...newLocations[idx],
            city,
            country,
            postalCode,
            species,
            streetAddress1,
            streetAddress2,
            locationTypes,
            latitude,
            longitude,
            salesContactName,
            salesContactPhone,
            salesContactEmail,
            sameSalesContact,
            title,
        };
    } else {
        newLocations.push(location);
    }
    return newLocations;
};

const LocationsReducer = (
    state: LocationsContextType = initialState,
    action: ActionType
): LocationsContextType => {
    switch (action.type) {
        case "API_ERROR":
            return {
                ...state,
                locations: state.locations,
                error: action.error,
            };
        case "UPDATE_LOCATION":
            return {
                ...state,
                locations: updateLocations(state.locations, action.location),
            };
        case "FETCH_LOCATIONS":
            return {
                ...state,
                locations: action.locations,
            };
        case "REMOVE_LOCATION":
            return {
                ...state,
                locations: deleteLocation(state.locations, action.location),
            };
        default:
            return {
                ...state,
                error: { nonFieldErrors: ["Unexpected Action"] },
            };
    }
};

export { LocationsReducer };
