// @flow
import React from "react";
import type { Node } from "react";
import Styles from "./index.module.scss";

type Props = { children?: Node, className?: string };

const Box = (props: Props) => {
    const { children } = props;
    const fullClass = props.className
        ? Styles.box_styles + " " + props.className
        : Styles.box_styles;
    return <div className={fullClass}>{children}</div>;
};

export default Box;
