// @flow
import type { ComponentType } from "react";
import React, { useContext } from "react";
import { BrowserRouter, Redirect, Route } from "react-router-dom";
import Styles from "./app.module.scss";
import Login from "./pages/login";
import Register from "./pages/register";
import ActivateAccount from "./pages/activate-account";
import UnVerifiedEmail from "./pages/unverified-email";
import ResetPassword from "./pages/reset-password";
import ChangePassword from "./pages/change-password";
import Profile from "./pages/profile";
import ConfirmPassword from "./pages/confirm-password";

import Store from "./state/store";
import rootReducer from "./state/reducers";
import StoreContext from "./state/context/store";

const ProtectedRoute = ({ component: Component, ...rest }: { component: ComponentType<any> }) => {
    const [store] = useContext(StoreContext);
    const { authenticated } = store.user;
    if (!authenticated) {
        return (
            <Route
                {...rest}
                render={props => {
                    return (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: props.location },
                            }}
                        />
                    );
                }}
            />
        );
    }

    return <Route {...rest} render={props => <Component {...props} />} />;
};

function Portal() {
    return (
        <div className={Styles.portal}>
            <Store rootReducer={rootReducer}>
                <BrowserRouter>
                    <Route path="/login" component={Login} />
                    <Route path="/register" component={Register} />
                    <Route path="/activate/:uid/:token" component={ActivateAccount} />
                    <Route path="/password/reset/confirm/:uid/:token" component={ConfirmPassword} />
                    <Route path="/reset-password" component={ResetPassword} />
                    <Route path="/unverified-email" component={UnVerifiedEmail} />
                    <ProtectedRoute path="/change-password" component={ChangePassword} />
                    <ProtectedRoute exact path="/" component={Profile} />
                </BrowserRouter>
            </Store>
        </div>
    );
}

export default Portal;
