// @flow
import React from "react";
import Styles from "./index.module.scss";

type Props = {
    type?: "text" | "password",
    error?: boolean,
    value: string,
    classSuffix?: string,
    onChange: (e: SyntheticInputEvent<HTMLInputElement>) => any,
    style?: { [string]: string },
    placeholder?: string,
};

export const TextBox = (props: Props) => {
    const {
        value,
        onChange,
        style,
        placeholder,
        type,
        classSuffix,
        error = false,
        ...rest
    } = props;

    let baseClass;

    if (error) {
        baseClass = Styles.error;
    } else {
        baseClass = Styles.input;
    }

    const className = classSuffix ? baseClass + " " + classSuffix : baseClass;

    return (
        <input
            {...rest}
            type={type || "text"}
            className={className}
            value={value || ""}
            onChange={onChange}
            style={style ? style : {}}
            placeholder={placeholder || ""}
        />
    );
};

export default TextBox;
