// @flow
import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import Styles from "./index.module.scss";
import Box from "../../components/box";
import TextBox from "../../components/textbox";
import Button from "../../components/button";
import {
    registerAction,
    populateRegistrationFormByToken,
} from "../../state/actions/user";
import logo from "../../assets/FSC-logo.png";
import StoreContext from "../../state/context/store";

type Props = { history: string };

const Register = (props: Props) => {
    const [store, dispatch] = useContext(StoreContext);
    const { user } = store;
    const { authenticated } = user;

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [fscLicenseCode, setFSCLicenseCode] = useState("");
    let [error, setError] = useState({});
    let [registrationToken, setRegistrationToken] = useState(null);
    const [isFetching, setIsFetching] = useState(false);

    const hash = window.location.hash;

    useEffect(() => {
        const setFields = async () => {
            if (hash.indexOf("=") !== -1) {
                const hashParts = hash.slice(1).split("=");
                const tempRegistrationToken =
                    hashParts.length === 2 && hashParts[0] === "token"
                        ? hashParts[1]
                        : null;
                if (tempRegistrationToken) {
                    const json = await populateRegistrationFormByToken(
                        tempRegistrationToken,
                        dispatch
                    );
                    if (json.nonFieldErrors !== undefined) {
                        setError(prevError => {
                            return {
                                ...prevError,
                                non_field_errors: json.nonFieldErrors,
                            };
                        });
                    } else {
                        setError({});
                    }

                    setEmail(json.email);
                    setFSCLicenseCode(json.fscLicenseCode);
                    setRegistrationToken(tempRegistrationToken);
                }
            }
        };
        setFields();
    }, [hash, dispatch]);

    useEffect(() => {
        if (authenticated) {
            props.history.push("/");
        }
    }, [authenticated, props.history]);

    const validate = (): boolean => {
        let valid = true;
        if (fscLicenseCode.length === 0) {
            setError(prevError => {
                return {
                    ...prevError,
                    fscLicenseCode:
                        "You must enter FSC License Code (e.g. FSC-C######)",
                };
            });
            valid = false;
        }
        if (email.length === 0) {
            setError(prevError => {
                return {
                    ...prevError,
                    email: "You must enter your Email Address",
                };
            });
            valid = false;
        }
        if (password.length === 0) {
            setError(prevError => {
                return { ...prevError, password: "You must enter a Password" };
            });
            valid = false;
        }
        return valid;
    };

    const register = async (e: SyntheticEvent<HTMLElement>) => {
        if (validate()) {
            setIsFetching(true);
            e.preventDefault();
            const response_json = await registerAction(
                email,
                password,
                fscLicenseCode,
                registrationToken,
                dispatch
            );
            setIsFetching(false);

            if (response_json !== undefined) {
                setError(prevError => {
                    // return { ...response_json, ...prevError };
                    return { ...response_json };
                });
            }
        }
    };

    const formFields = ["email", "password", "fscLicenseCode"];
    const nonFieldErrors = Object.keys(error)
        .filter(field => !formFields.includes(field))
        .map(key => {
            return <div className="error">{error[key]}</div>;
        });
    const formErrorStyle = { border: "2px solid red" };

    const createAccountPage = (
        <div className={`${Styles.register} box_wrapper`}>
            <div className="registration-logo-wrapper">
                <img
                    src={logo}
                    className="logo"
                    alt="Forest Stewardship Council"
                />
                <span className="logo-site-text">
                    Supplier
                    <br />
                    Portal
                </span>
            </div>
            <Box className={Styles.register_box}>
                <h1>Create an Account</h1>
                <p className="sub-head">
                    Already have an account? <Link to="/login">Log In</Link>
                </p>
                <p className="sub-head">
                    Non-US? Email{" "}
                    <a
                        href={
                            "mailto:info@us.fsc.org?subject=International Supplier Account"
                        }
                    >
                        info@us.fsc.org
                    </a>{" "}
                    to create an account.
                </p>
                <form>
                    <TextBox
                        style={error.email && formErrorStyle}
                        value={email}
                        onChange={({
                            target,
                        }: SyntheticInputEvent<HTMLInputElement>) => {
                            setEmail(target.value);
                            setError(prevError => {
                                const {
                                    email,
                                    nonFieldErrors,
                                    ...remaining
                                } = prevError;
                                return remaining;
                            });
                        }}
                        autoComplete="email"
                        placeholder="Work Email"
                    />
                    {error.email && <div className="error">{error.email}</div>}
                    <TextBox
                        style={error.password && formErrorStyle}
                        value={password}
                        type="password"
                        onChange={({
                            target,
                        }: SyntheticInputEvent<HTMLInputElement>) => {
                            setPassword(target.value);
                            setError(prevError => {
                                const {
                                    password,
                                    nonFieldErrors,
                                    ...remaining
                                } = prevError;
                                return remaining;
                            });
                        }}
                        autoComplete="current-password"
                        placeholder="Password"
                    />
                    <div className={"helpText"}>
                        <span>
                            Password must be at least 8 characters and not
                            obvious.
                        </span>
                    </div>
                    {error.password && (
                        <div className="error">{error.password}</div>
                    )}
                    <TextBox
                        style={error.fscLicenseCode && formErrorStyle}
                        value={fscLicenseCode}
                        onChange={({
                            target,
                        }: SyntheticInputEvent<HTMLInputElement>) => {
                            setFSCLicenseCode(target.value);
                            setError(prevError => {
                                const {
                                    fscLicenseCode,
                                    nonFieldErrors,
                                    ...remaining
                                } = prevError;
                                return remaining;
                            });
                        }}
                        placeholder="FSC License Code (ex: FSC-C123456)"
                    />
                    <div className={"helpText"}>
                        <span>
                            Find your code here:{" "}
                            <a href={"https://info.fsc.org/certificate.php"}>
                                https://info.fsc.org/certificate.php
                            </a>
                        </span>
                    </div>

                    {error.fscLicenseCode && (
                        <div className="error">{error.fscLicenseCode}</div>
                    )}
                    {nonFieldErrors}
                </form>
                <Button
                    label="Create Account"
                    disabled={isFetching}
                    onClick={register}
                />
            </Box>
        </div>
    );

    return createAccountPage;
};

export default Register;
