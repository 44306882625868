// @flow
import { SPECIES_ACTIONS } from "../constants";

import type {
    ActionType,
    SpeciesReducerType,
    SpeciesType,
} from "../types/species";

const initialState: SpeciesType = [];

const SpeciesReducer: SpeciesReducerType = (
    state: SpeciesType = initialState,
    { type, species }: ActionType
): SpeciesType => {
    switch (type) {
        case SPECIES_ACTIONS.FETCH_SPECIES:
            return species;
        default:
            return state;
    }
};

export { SpeciesReducer };
