// @flow
import config from "../../config";

import type {
    Dispatch,
    ProductLocationAddFormDataType,
    ProductLocationUpdateFormDataType,
    ProductLocationType,
    ProductLocationsType,
    EditAllProductLocationsFormDataType,
} from "../types/product-locations";
import type { ResponseErrorType } from "../types/globals";
import { buildHeaders } from "../../lib/helpers";
import { USER_ACTIONS } from "../constants";

export const updateMultipleProductLocations = async (
    dispatch: Dispatch,
    editAllProductLocationsFormData: EditAllProductLocationsFormDataType,
): Promise<any> => {
    try {
        const result = await fetch(`${config.apiRoot}/product-location/bulk-update/`, {
            method: "PUT",
            headers: buildHeaders(),
            body: JSON.stringify({ ...editAllProductLocationsFormData }),
        });
        if (result.status === 400) {
            const json: ResponseErrorType = await result.json();
            if (Array.isArray(json.non_field_errors) && json.non_field_errors.length > 0) {
                return dispatch({
                    type: "API_ERROR",
                    error: json,
                });
            }
            return dispatch({
                type: "API_ERROR",
                error: { nonFieldErrors: ["There was an unknown error"] },
            });
        } else if (result.status === 401) {
            return dispatch({ type: USER_ACTIONS.LOGOUT_USER });
        } else if (Math.floor(result.status / 100) === 5) {
            dispatch({
                type: "API_ERROR",
                error: { nonFieldErrors: ["There was an unknown error"] },
            });
        } else if (result.status === 200) {
            const json: ProductLocationsType = await result.json();
            return dispatch({
                type: "FETCH_PRODUCT_LOCATIONS",
                productLocations: json,
            });
        }
    } catch (err) {
        console.error(err);
        return dispatch({
            type: "API_ERROR",
            error: { nonFieldErrors: [err.message] },
        });
    }
};

export const deleteProductLocation = async (
    dispatch: Dispatch,
    productLocationId: number,
): Promise<any> => {
    try {
        const result = await fetch(`${config.apiRoot}/product-location/${productLocationId}/`, {
            method: "DELETE",
            headers: buildHeaders(),
            body: JSON.stringify({}),
        });
        if (result.status === 400) {
            const json: ResponseErrorType = await result.json();
            if (Array.isArray(json.non_field_errors) && json.non_field_errors.length > 0) {
                return dispatch({
                    type: "API_ERROR",
                    error: json,
                });
            }
            return dispatch({
                type: "API_ERROR",
                error: { nonFieldErrors: ["There was an unknown error"] },
            });
        } else if (result.status === 401) {
            return dispatch({ type: USER_ACTIONS.LOGOUT_USER });
        } else if (Math.floor(result.status / 100) === 5) {
            return dispatch({
                type: "API_ERROR",
                error: { nonFieldErrors: ["There was an unknown error"] },
            });
        } else if (result.status === 204) {
            return getProductLocations(dispatch);
        }
    } catch (err) {
        console.error(err);
        return dispatch({
            type: "API_ERROR",
            error: { nonFieldErrors: [err.message] },
        });
    }
};

export const updateProductLocation = async (
    dispatch: Dispatch,
    productLocationFormData: ProductLocationUpdateFormDataType,
): Promise<any> => {
    try {
        const { id, ...formData } = productLocationFormData;

        const result = await fetch(`${config.apiRoot}/product-location/${id}/`, {
            method: "PATCH",
            headers: buildHeaders(),
            body: JSON.stringify({ ...formData }),
        });
        if (result.status === 400) {
            const json: ResponseErrorType = await result.json();
            if (Array.isArray(json.non_field_errors) && json.non_field_errors.length > 0) {
                return dispatch({
                    type: "API_ERROR",
                    error: json,
                });
            }
            return dispatch({
                type: "API_ERROR",
                error: { nonFieldErrors: ["There was an unknown error"] },
            });
        } else if (result.status === 401) {
            return dispatch({ type: USER_ACTIONS.LOGOUT_USER });
        } else if (Math.floor(result.status / 100) === 5) {
            return dispatch({
                type: "API_ERROR",
                error: { nonFieldErrors: ["There was an unknown error"] },
            });
        } else if (result.status === 200) {
            const json: ProductLocationType = await result.json();
            return dispatch({
                type: "UPDATE_PRODUCT_LOCATION",
                productLocation: json,
            });
        }
    } catch (err) {
        console.error(err);
        return dispatch({
            type: "API_ERROR",
            error: { nonFieldErrors: [err.message] },
        });
    }
};

export const addProductLocation = async (
    dispatch: Dispatch,
    productLocationFormData: ProductLocationAddFormDataType,
): Promise<any> => {
    try {
        const headers = buildHeaders();
        const result = await fetch(`${config.apiRoot}/product-location/`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ ...productLocationFormData }),
        });
        if (result.status === 400) {
            const json: ResponseErrorType = await result.json();
            if (Array.isArray(json.non_field_errors) && json.non_field_errors.length > 0) {
                return dispatch({
                    type: "API_ERROR",
                    error: json,
                });
            }
            return dispatch({
                type: "API_ERROR",
                error: { nonFieldErrors: ["There was an unknown error"] },
            });
        } else if (result.status === 401) {
            return dispatch({ type: USER_ACTIONS.LOGOUT_USER });
        } else if (Math.floor(result.status / 100) === 5) {
            return dispatch({
                type: "API_ERROR",
                error: { nonFieldErrors: ["There was an unknown error"] },
            });
        } else if (result.status === 201) {
            const json: ProductLocationsType = await result.json();
            return dispatch({
                type: "ADD_PRODUCT_LOCATIONS",
                productLocations: json,
            });
        }
    } catch (err) {
        console.error(err);
        return dispatch({
            type: "API_ERROR",
            error: { nonFieldErrors: [err.message] },
        });
    }
};

// todo: let's send chosenLocations to the backend, and have the backend process them into many productLocations.

export const getProductLocations = async (dispatch: Dispatch): Promise<any> => {
    try {
        const headers = buildHeaders();
        const result = await fetch(`${config.apiRoot}/product-location/`, {
            method: "GET",
            headers: headers,
        });
        if (result.status === 400) {
            const json: ResponseErrorType = await result.json();
            if (json.non_field_errors && json.non_field_errors.length > 0) {
                return dispatch({
                    type: "API_ERROR",
                    error: json,
                });
            }
            return dispatch({
                type: "API_ERROR",
                error: { nonFieldErrors: ["There was an unknown error"] },
            });
        } else if (result.status === 401) {
            return dispatch({ type: USER_ACTIONS.LOGOUT_USER });
        } else if (Math.floor(result.status / 100) === 5) {
            return dispatch({
                type: "API_ERROR",
                error: { nonFieldErrors: ["There was an unknown error"] },
            });
        } else if (result.status === 200) {
            const json: ProductLocationsType = await result.json();
            return dispatch({
                type: "FETCH_PRODUCT_LOCATIONS",
                productLocations: json,
            });
        }
    } catch (err) {
        console.error(err);
        return dispatch({
            type: "API_ERROR",
            error: { nonFieldErrors: [err.message] },
        });
    }
};
