// @flow
import React, { useEffect, useState, useContext } from "react";
import Styles from "./index.module.scss";
import Header from "../../components/header";
import Company from "./company";
import Analytics from "./analytics";
import Products from "./products";
import Locations from "./locations";
import Pending from "./pending";
import { getMe, patchUserField } from "../../state/actions/user";
import StoreContext from "../../state/context/store";
import { getLocations } from "../../state/actions/locations";
import { getProductLocations } from "../../state/actions/product-locations";
import { getSections } from "../../state/actions/section";
import { getSpecies } from "../../state/actions/species";
import UnVerifiedEmail from "../unverified-email";

type Props = {};

const Profile = (props: Props) => {
    const [store, dispatch] = useContext(StoreContext);
    const { user, locationsReducer, productLocationsReducer } = store;
    const { locations } = locationsReducer;
    const { productLocations } = productLocationsReducer;
    const { companyInfoCompleted, companyName, status, isEmailVerified } = user;
    const activeTabs = ["active", "hidden", "archived"];
    const useActiveTabs = activeTabs.includes(status);

    const getActiveTab = () => {
        return "company";
        // if (useActiveTabs) {
        //     return "company";
        // } else if (!companyInfoCompleted) {
        //     return "company";
        // } else if (locations.length === 0) {
        //     return "locations";
        // } else {
        //     return "products";
        // }
    };
    const [activeTab, setActiveTab] = useState(getActiveTab());
    const token = window.localStorage.getItem("token");

    useEffect(() => {
        if (token) {
            getMe(token, dispatch);
            getLocations(dispatch);
            getProductLocations(dispatch);
            getSections(dispatch);
            getSpecies(dispatch);
        }
    }, [token, dispatch]);

    const testLocationTypeLength = (prev, curr) => {
        if (curr.locationTypes.length === 0) {
            return true;
        }
        return prev;
    };

    const missingLocationTypes = locations.reduce(
        testLocationTypeLength,
        false
    );

    const testSpeciesLength = (prev, curr) => {
        if (curr.species.length === 0) {
            return true;
        }
        return prev;
    };

    const missingSpecies = locations.reduce(testSpeciesLength, false);

    return (
        <>
            {!status && <div></div>}

            {!isEmailVerified && status && <UnVerifiedEmail />}

            {isEmailVerified && (
                <div className={Styles.profile}>
                    <Header />
                    <div className='inner'>
                        <div className={Styles["company-name"]}>
                            {companyName && (
                                <span
                                    className={
                                        activeTab === "company"
                                            ? Styles["editable"]
                                            : Styles["not-editable"]
                                    }
                                    contentEditable={activeTab === "company"}
                                    suppressContentEditableWarning={true}
                                    onBlur={(e) => {
                                        let newCompanyName = e.target.innerText;
                                        newCompanyName = newCompanyName.replace(
                                            /[\n\r]+/g,
                                            " "
                                        );
                                        newCompanyName = newCompanyName.replace(
                                            /\s{2,10}/g,
                                            " "
                                        );
                                        newCompanyName = newCompanyName.trim();

                                        if (
                                            newCompanyName === "" ||
                                            newCompanyName === " "
                                        ) {
                                            newCompanyName = companyName;
                                        }

                                        if (newCompanyName !== companyName) {
                                            patchUserField(
                                                token,
                                                dispatch,
                                                "companyName",
                                                newCompanyName
                                            );
                                        }
                                        e.target.innerText = companyName;
                                    }}
                                >
                                    {companyName}
                                </span>
                            )}
                        </div>

                        {useActiveTabs && (
                            <>
                                <div className={Styles.tabToggles}>
                                    <span
                                        className={
                                            activeTab === "company"
                                                ? Styles.activeTab
                                                : ""
                                        }
                                        onClick={() => setActiveTab("company")}
                                    >
                                        Company Profile
                                    </span>
                                    <span
                                        className={
                                            activeTab === "locations"
                                                ? Styles.activeTab
                                                : ""
                                        }
                                        onClick={() =>
                                            setActiveTab("locations")
                                        }
                                    >
                                        Locations
                                    </span>
                                    <span
                                        className={
                                            activeTab === "products"
                                                ? Styles.activeTab
                                                : ""
                                        }
                                        onClick={() => setActiveTab("products")}
                                    >
                                        Products
                                    </span>
                                    <span
                                        className={
                                            activeTab === "analytics"
                                                ? Styles.activeTab
                                                : ""
                                        }
                                        onClick={() =>
                                            setActiveTab("analytics")
                                        }
                                    >
                                        Analytics
                                    </span>
                                </div>
                            </>
                        )}

                        {status === "unverified" && (
                            <div className={Styles.tabToggles}>
                                <span
                                    className={`
                                ${
                                    activeTab === "company"
                                        ? Styles.activeTab
                                        : ""
                                }
                                ${
                                    companyInfoCompleted
                                        ? Styles.isCompleted
                                        : ""
                                }
                                ${Styles.isAccessible}
                            `}
                                    onClick={() => setActiveTab("company")}
                                >
                                    <span className={Styles["step-number"]}>
                                        1
                                    </span>
                                    Complete Company Information
                                </span>
                                <span
                                    className={`
                                ${
                                    activeTab === "locations"
                                        ? Styles.activeTab
                                        : ""
                                }
                                ${
                                    companyInfoCompleted
                                        ? Styles.isAccessible
                                        : Styles.isLocked
                                }
                                ${
                                    locations.length > 0 &&
                                    !missingLocationTypes &&
                                    !missingSpecies
                                        ? Styles.isCompleted
                                        : ""
                                }
                                `}
                                    onClick={() => {
                                        if (companyInfoCompleted) {
                                            setActiveTab("locations");
                                        }
                                    }}
                                >
                                    <span className={Styles["step-number"]}>
                                        2
                                    </span>
                                    Add Locations
                                </span>
                                <span
                                    className={`
                                ${
                                    activeTab === "products"
                                        ? Styles.activeTab
                                        : ""
                                }
                                ${
                                    companyInfoCompleted && locations.length > 0
                                        ? Styles.isAccessible
                                        : Styles.isLocked
                                }
                                ${
                                    locations.length > 0 &&
                                    productLocations.length > 0 &&
                                    !missingLocationTypes &&
                                    !missingSpecies
                                        ? Styles.isCompleted
                                        : ""
                                }
                                `}
                                    onClick={() => {
                                        if (
                                            companyInfoCompleted &&
                                            locations.length > 0 &&
                                            !missingLocationTypes &&
                                            !missingSpecies
                                        ) {
                                            setActiveTab("products");
                                        }
                                    }}
                                >
                                    <span className={Styles["step-number"]}>
                                        3
                                    </span>
                                    Add Products
                                </span>
                            </div>
                        )}

                        {status !== "pending" && (
                            <div className={Styles["tab-content"]}>
                                {activeTab === "company" && (
                                    <Company
                                        setActiveTab={setActiveTab}
                                        status={status}
                                    />
                                )}
                                {activeTab === "locations" && (
                                    <Locations
                                        missingLocationTypes={
                                            missingLocationTypes
                                        }
                                        missingSpecies={missingSpecies}
                                        setActiveTab={setActiveTab}
                                        status={status}
                                    />
                                )}
                                {activeTab === "products" && (
                                    <Products
                                        setActiveTab={setActiveTab}
                                        status={status}
                                    />
                                )}
                                {activeTab === "analytics" && <Analytics />}
                            </div>
                        )}

                        {status === "pending" && (
                            <div className={Styles["tab-content"]}>
                                <Pending setActiveTab={setActiveTab} />
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default Profile;
