// @flow
// Generic actions
export const API_ERROR = "API_ERROR";

// UserReducer Actions
export const LOGOUT_USER = "LOGOUT_USER";
export const LOGIN_USER = "LOGIN_USER";
export const ACTIVATE = "ACTIVATE";
export const PASSWORD_RESET = "PASSWORD_RESET";
export const PASSWORD_RESET_CONFIRM = "PASSWORD_RESET_CONFIRM";
export const SET_ME = "SET_ME";
export const RECAPTCHA_VERIFY = "RECAPTCHA_VERIFY";
export const SET_TOKEN = "SET_TOKEN";
export const UPDATE_USER_FIELD = "UPDATE_USER_FIELD";
export const PASSWORD_CHANGE = "PASSWORD_CHANGE";
export const RESET_API_ERROR = "RESET_API_ERROR";

// Section Actions
export const FETCH_SECTIONS = "FETCH_SECTIONS";

//Species Actions
export const FETCH_SPECIES = "FETCH_SPECIES";

// Aggregations
export const USER_ACTIONS = Object.freeze({
    ACTIVATE,
    API_ERROR,
    LOGIN_USER,
    LOGOUT_USER,
    PASSWORD_RESET,
    PASSWORD_RESET_CONFIRM,
    SET_ME,
    SET_TOKEN,
    UPDATE_USER_FIELD,
    RECAPTCHA_VERIFY,
    RESET_API_ERROR,
    PASSWORD_CHANGE,
});

export const SECTION_ACTIONS = Object.freeze({
    FETCH_SECTIONS,
    API_ERROR,
});

export const SPECIES_ACTIONS = Object.freeze({
    FETCH_SPECIES,
    API_ERROR,
});

export const US_STATE_OPTIONS = [
    { value: "AL", label: "Alabama" },
    { value: "AK", label: "Alaska" },
    { value: "AZ", label: "Arizona" },
    { value: "AR", label: "Arkansas" },
    { value: "CA", label: "California" },
    { value: "CO", label: "Colorado" },
    { value: "CT", label: "Connecticut" },
    { value: "DE", label: "Delaware" },
    { value: "FL", label: "Florida" },
    { value: "GA", label: "Georgia" },
    { value: "HI", label: "Hawaii" },
    { value: "ID", label: "Idaho" },
    { value: "IL", label: "Illinois" },
    { value: "IN", label: "Indiana" },
    { value: "IA", label: "Iowa" },
    { value: "KS", label: "Kansas" },
    { value: "KY", label: "Kentucky" },
    { value: "LA", label: "Louisiana" },
    { value: "ME", label: "Maine" },
    { value: "MD", label: "Maryland" },
    { value: "MA", label: "Massachusetts" },
    { value: "MI", label: "Michigan" },
    { value: "MN", label: "Minnesota" },
    { value: "MS", label: "Mississippi" },
    { value: "MO", label: "Missouri" },
    { value: "MT", label: "Montana" },
    { value: "NE", label: "Nebraska" },
    { value: "NV", label: "Nevada" },
    { value: "NH", label: "New Hampshire" },
    { value: "NJ", label: "New Jersey" },
    { value: "NM", label: "New Mexico" },
    { value: "NY", label: "New York" },
    { value: "NC", label: "North Carolina" },
    { value: "ND", label: "North Dakota" },
    { value: "OH", label: "Ohio" },
    { value: "OK", label: "Oklahoma" },
    { value: "OR", label: "Oregon" },
    { value: "PA", label: "Pennsylvania" },
    { value: "RI", label: "Rhode Island" },
    { value: "SC", label: "South Carolina" },
    { value: "SD", label: "South Dakota" },
    { value: "TN", label: "Tennessee" },
    { value: "TX", label: "Texas" },
    { value: "UT", label: "Utah" },
    { value: "VT", label: "Vermont" },
    { value: "VA", label: "Virginia" },
    { value: "WA", label: "Washington" },
    { value: "WV", label: "West Virginia" },
    { value: "WI", label: "Wisconsin" },
    { value: "WY", label: "Wyoming" },
];

export const CA_PROVINCE_OPTIONS = [
    { value: "AB", label: "Alberta" },
    { value: "BC", label: "British Columbia" },
    { value: "MB", label: "Manitoba" },
    { value: "NB", label: "New Brunswick" },
    { value: "NL", label: "Newfoundland and Labrador" },
    { value: "NS", label: "Nova Scotia" },
    { value: "ON", label: "Ontario" },
    { value: "PE", label: "Prince Edward Island" },
    { value: "QC", label: "Quebec" },
    { value: "SK", label: "Saskatchewan" },
    { value: "NT", label: "Northwest Territories" },
    { value: "NU", label: "Nunavut" },
    { value: "YT", label: "Yukon" },
];

export const MX_STATE_OPTIONS = [
    { value: "AGU", label: "Aguascalientes" },
    { value: "BCN", label: "Baja California" },
    { value: "BCS", label: "Baja California Sur" },
    { value: "CAM", label: "Campeche" },
    { value: "CHH", label: "Chihuahua" },
    { value: "CHP", label: "Chiapas" },
    { value: "COA", label: "Coahuila de Zaragoza" },
    { value: "COL", label: "Colima" },
    { value: "DIF", label: "Ciudad de México" },
    { value: "DUR", label: "Durango" },
    { value: "GRO", label: "Guerrero" },
    { value: "GUA", label: "Guanajuato" },
    { value: "HID", label: "Hidalgo" },
    { value: "JAL", label: "Jalisco" },
    { value: "MEX", label: "México" },
    { value: "MIC", label: "Michoacán de Ocampo" },
    { value: "MOR", label: "Morelos" },
    { value: "NAY", label: "Nayarit" },
    { value: "NLE", label: "Nuevo León" },
    { value: "OAX", label: "Oaxaca" },
    { value: "PUE", label: "Puebla" },
    { value: "QUE", label: "Querétaro" },
    { value: "ROO", label: "Quintana Roo" },
    { value: "SIN", label: "Sinaloa" },
    { value: "SLP", label: "San Luis Potosí" },
    { value: "SON", label: "Sonora" },
    { value: "TAB", label: "Tabasco" },
    { value: "TAM", label: "Tamaulipas" },
    { value: "TLA", label: "Tlaxcala" },
    { value: "VER", label: "Veracruz de Ignacio de la Llave" },
    { value: "YUC", label: "Yucatán" },
    { value: "ZAC", label: "Zacatecas" },
];

export const LOCATION_TYPES_OPTIONS = [
    { value: "retail", label: "Retail" },
    {
        value: "specialty-woodworking",
        label: "Specialty Woodworking",
    },
    {
        value: "wholesale",
        label: "Wholesale Distribution",
    },
    {
        value: "secondary-manufacturing",
        label: "Secondary Manufacturing",
    },
    {
        value: "primary-manufacturing",
        label: "Primary Manufacturing",
    },
];
