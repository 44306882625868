// @flow
import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import Styles from "./index.module.scss";
import Button from "../../../../components/button/";
import Checkbox from "../../../../components/checkbox/";
import Radio from "../../../../components/radio/";
import {
    addProductLocation,
    updateProductLocation,
    deleteProductLocation,
    updateMultipleProductLocations,
} from "../../../../state/actions/product-locations";
import type { ProductLocationType } from "../../../../state/types/product-locations";
import BasicMulti from "../../../../components/multi-select";
import StoreContext from "../../../../state/context/store";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";

type PropsType = {
    productLocation?: ProductLocationType,
    productLocationName?: string,
    parentLocation?: number,
    parentProduct?: number,
    setShowSingleProductLocationForm?: Function,
    setShowAddProductToLocationForm?: Function,
    setShowEditAllProductLocationForm?: Function,
    showSingleProductLocationForm?: boolean,
    showAddProductToLocationForm?: boolean,
    showEditAllProductLocationForm?: boolean,
    className: string,
    setEditProductLocationId?: (number | null) => void,
};

const ProductLocationForm = (props: PropsType) => {
    const {
        productLocation,
        productLocationName,
        setShowSingleProductLocationForm,
        setShowAddProductToLocationForm,
        parentLocation,
        parentProduct,
        setShowEditAllProductLocationForm,
        showAddProductToLocationForm,
        showEditAllProductLocationForm,
        className,
        setEditProductLocationId,
    } = props;

    const addForm = !(
        (productLocation && productLocation.id) ||
        showEditAllProductLocationForm
    );

    const [addFormLocationsAll, setAddFormLocationsAll] = useState(false);

    const showProductSelect =
        addForm &&
        !(setShowAddProductToLocationForm || setShowEditAllProductLocationForm);

    const [store, dispatch] = useContext(StoreContext);
    const { sections, productLocationsReducer, locationsReducer } = store;
    const { productLocations } = productLocationsReducer;
    const { locations } = locationsReducer;

    // local state properties for the form
    const [product, setProduct] = useState(
        productLocation
            ? productLocation.product
            : parentProduct
            ? parentProduct
            : null
    );
    const [retail, setRetail] = useState(
        productLocation ? productLocation.retail : false
    );
    const [contractor, setContractor] = useState(
        productLocation ? productLocation.contractor : false
    );
    const [wholesale, setWholesale] = useState(
        productLocation ? productLocation.wholesale : false
    );
    const [availability, setAvailability] = useState(
        productLocation ? productLocation.availability : "regularly-stocked"
    );
    const [status, setStatus] = useState(
        productLocation ? productLocation.status : "available"
    );

    const [chosenSection, setChosenSection] = useState();
    const [chosenCategory, setChosenCategory] = useState();

    const [chosenLocations, setChosenLocations] = useState(
        parentLocation ? [parentLocation.id] : []
    );

    console.log("chosenLocations", chosenLocations);

    useEffect(() => {
        if (productLocation && productLocation.id) {
            setChosenLocations([productLocation.location]);
        } else {
            setChosenLocations(locations.length === 1 ? [locations[0].id] : []);
        }
    }, [locations, productLocation]);

    const customSelectStyles = {
        control: (provided, state) => ({
            ...provided,
            width: 500,
        }),
    };
    const placeholder = "Start typing to select a product.";
    const defaultSelectOption = { label: placeholder, value: "" };

    const [addProductMetaDataVisible, setAddProductMetaDataVisible] = useState(
        !showProductSelect
    );
    const [productChoiceMethod, setProductChoiceMethod] = useState("select"); // or "browse"
    let productOptions = [defaultSelectOption];

    // validate that buyer type and locations are filled out.
    const submitDisabled = () => {
        if (locations.length > 0) {
            const buyerTypeEntered = retail || contractor || wholesale;
            if (locations.length === 1) {
                return !buyerTypeEntered;
            } else {
                return !(
                    buyerTypeEntered &&
                    (chosenLocations.length > 0 ||
                        showEditAllProductLocationForm ||
                        showAddProductToLocationForm)
                );
            }
        } else {
            return false;
        }
    };

    if (sections.length > 0) {
        sections.forEach((section) => {
            section.categories.forEach((category) => {
                category.products.forEach((product) => {
                    const isAlreadySelected = (pl) => {
                        return pl.product === product.id;
                    };
                    const isAvailableOption =
                        productLocations.filter(isAlreadySelected).length === 0;
                    if (isAvailableOption) {
                        let productOption =
                            section.name +
                            " > " +
                            category.name +
                            " > " +
                            product.name;
                        productOptions.push({
                            value: product.id,
                            label: productOption,
                        });
                    }
                });
            });
        });
    }

    const handleDelete = (e) => {
        e.preventDefault();
        if (productLocation) {
            if (
                window.confirm(
                    "You are about to remove a product from a location.  Proceed?"
                )
            ) {
                setAddProductMetaDataVisible(false);
                setProduct("");
                if (setShowSingleProductLocationForm !== undefined) {
                    setShowSingleProductLocationForm(false);
                }
                if (setShowAddProductToLocationForm !== undefined) {
                    setShowAddProductToLocationForm(false);
                }
                if (setShowEditAllProductLocationForm !== undefined) {
                    setShowEditAllProductLocationForm(false);
                }

                if (setEditProductLocationId !== undefined) {
                    setEditProductLocationId(null);
                }
                deleteProductLocation(dispatch, productLocation.id);
            }
        }
    };

    const handleSubmit = (e: SyntheticEvent<HTMLElement>) => {
        e.preventDefault();
        if (showEditAllProductLocationForm) {
            const ids = productLocations
                .filter((pl) => {
                    return pl.product === parentProduct;
                })
                .map((pl) => pl.id);

            updateMultipleProductLocations(dispatch, {
                productLocations: ids,
                retail,
                contractor,
                wholesale,
                availability,
                status,
            });
        } else if (productLocation) {
            updateProductLocation(dispatch, {
                id: productLocation.id,
                product: productLocation.product,
                location: productLocation.location,
                retail,
                contractor,
                wholesale,
                availability,
                status,
            });
        } else {
            addProductLocation(dispatch, {
                product,
                chosenLocations,
                retail,
                contractor,
                wholesale,
                availability,
                status,
            });
            setAddProductMetaDataVisible(false);
            setProduct("");
        }
        if (setShowAddProductToLocationForm !== undefined) {
            setShowAddProductToLocationForm(false);
        }
        if (setShowSingleProductLocationForm !== undefined) {
            setShowSingleProductLocationForm(false);
        }
        if (setShowEditAllProductLocationForm !== undefined) {
            setShowEditAllProductLocationForm(false);
        }
        if (setEditProductLocationId !== undefined) {
            setEditProductLocationId(null);
        }
    };

    const handleCancel = (e) => {
        e.preventDefault();
        setAddProductMetaDataVisible(false);
        setProduct("");
        if (setShowSingleProductLocationForm !== undefined) {
            setShowSingleProductLocationForm(false);
        }
        if (setShowAddProductToLocationForm !== undefined) {
            setShowAddProductToLocationForm(false);
        }
        if (setShowEditAllProductLocationForm !== undefined) {
            setShowEditAllProductLocationForm(false);
        }
        if (setEditProductLocationId !== undefined) {
            setEditProductLocationId(null);
        }
    };

    const handleSelectChange = (arg) => {
        setAddProductMetaDataVisible(true);
        setProduct(arg.value);
        setProductChoiceMethod("select");
    };

    const sectionChoicesEntries = sections.map((section) => {
        return (
            <li key={section.id}>
                <span
                    className={Styles.browseItem}
                    onClick={() => {
                        setChosenSection(section);
                        setChosenCategory("");
                    }}
                >
                    {section.name} <span className={Styles.divider}>&gt;</span>
                </span>
            </li>
        );
    });

    const sectionChoices = (
        <ul className={Styles["browse-choices"]}>{sectionChoicesEntries}</ul>
    );

    let categoryChoices = <ul></ul>;

    if (chosenSection) {
        const categoryChoicesEntries = chosenSection.categories.map(
            (category) => {
                return (
                    <li key={category.id}>
                        <span
                            className={Styles.browseItem}
                            onClick={() => {
                                setChosenCategory(category);
                            }}
                        >
                            {category.name}{" "}
                            <span className={Styles.divider}>&gt;</span>
                        </span>
                    </li>
                );
            }
        );
        categoryChoices = (
            <ul className={Styles["browse-choices"]}>
                {categoryChoicesEntries}
            </ul>
        );
    }

    let productChoices = <ul></ul>;

    if (chosenCategory) {
        const productChoicesEntries = chosenCategory.products.map((product) => {
            return (
                <li key={product.id}>
                    <span
                        className={Styles.browseItem}
                        onClick={() => {
                            setProduct(product.id);
                            setAddProductMetaDataVisible(true);
                            setProductChoiceMethod("select");
                        }}
                    >
                        {product.name}
                    </span>
                </li>
            );
        });
        productChoices = (
            <ul className={Styles.browseChoices}>{productChoicesEntries}</ul>
        );
    }

    const getLocationOptions = () => {
        const alreadyUsedLocations = productLocations
            .filter((pl) => {
                return pl.product === parentProduct;
            })
            .map((pl) => {
                return pl.location;
            });
        const availableLocations = locations.filter((location) => {
            return !alreadyUsedLocations.includes(location.id);
        });

        return availableLocations.map((location) => {
            return { value: location.id, label: location.streetAddress1 };
        });
    };

    const locationOptionsCount = getLocationOptions().length;
    const firstLocationOptionsValue =
        locationOptionsCount === 1 ? getLocationOptions()[0].value : null;

    const getDefaultLocationValue = (productLocation: ProductLocationType) => {
        if (productLocations.length > 0 && productLocation) {
            const selectedOptions = getLocationOptions().filter(
                (locationOption) =>
                    productLocations.filter(
                        (pl) =>
                            pl.product === productLocation.id &&
                            pl.location === locationOption.value
                    ).length > 0
            );
            return selectedOptions;
        } else {
            if (locationOptionsCount === 1) {
                return getLocationOptions()[0];
            } else {
                return null;
            }
        }
    };

    useEffect(() => {
        if (locationOptionsCount === 1) {
            setChosenLocations([firstLocationOptionsValue]);
        }
    }, [firstLocationOptionsValue, locationOptionsCount]);

    useEffect(() => {
        if (chosenLocations.length === 0) {
            setAddFormLocationsAll(false);
        }
        if (chosenLocations.length === locationOptionsCount) {
            setAddFormLocationsAll(true);
        }
    }, [chosenLocations]);

    const formatOptionLabel = ({ value, label }) => {
        const labelParts = label.split(">");

        if (value === "") {
            return placeholder;
        } else {
            return (
                <>
                    {labelParts[0]} > {labelParts[1]} >{" "}
                    <span className={Styles.selectProduct}>
                        {labelParts[2]}
                    </span>
                </>
            );
        }
    };

    console.log("chosenLocations", chosenLocations);

    return (
        <div className={Styles["add-products"]}>
            {productLocation && <h2>{productLocationName}</h2>}
            {showEditAllProductLocationForm && (
                <h2>Edit Product for All Locations</h2>
            )}
            {showAddProductToLocationForm && (
                <h2>Add Product to New Location</h2>
            )}
            <form className={className}>
                {showProductSelect && (
                    <>
                        <Select
                            formatOptionLabel={formatOptionLabel}
                            placeholder={placeholder}
                            options={productOptions}
                            name={"product"}
                            className={Styles["select-product-dropdown"]}
                            classNamePrefix={"product"}
                            styles={customSelectStyles}
                            searchable={true}
                            clearable={true} // none of this appears to be working
                            backspaceRemoves={true} // none of this appears to be working
                            deleteRemoves={true} // none of this appears to be working
                            escapeRemoves={true} // none of this appears to be working
                            value={productOptions.find((option) => {
                                return option.value === product;
                            })}
                            onChange={handleSelectChange}
                        />
                        <div className={Styles.browse}>
                            or{" "}
                            <span
                                onClick={() => {
                                    setAddProductMetaDataVisible(false);
                                    setProduct("");
                                    setChosenCategory("");
                                    setChosenSection("");
                                    setProductChoiceMethod("browse");
                                }}
                            >
                                Select Product by Category
                            </span>
                        </div>

                        {productChoiceMethod === "browse" && (
                            <div className={Styles["categories"]}>
                                {sectionChoices}
                                {categoryChoices}
                                {productChoices}
                            </div>
                        )}
                    </>
                )}

                {addProductMetaDataVisible && (
                    <div className={Styles["product-metadata-container"]}>
                        <div>
                            <h3>
                                What customer types can purchase this product?
                            </h3>
                            <ReactTooltip
                                id={`tooltip_buyerType1`}
                                type={"info"}
                                effect={"solid"}
                                className={"custom-tooltip"}
                            >
                                <p>
                                    All types of secondary manufacturers,
                                    wholesale distributors, retailers
                                </p>
                            </ReactTooltip>

                            <ReactTooltip
                                id={`tooltip_buyerType2`}
                                type={"info"}
                                effect={"solid"}
                                className={"custom-tooltip"}
                            >
                                <p>
                                    Flooring or Building contractors, Cabinet
                                    shops, Architectural millworkers, Property
                                    Owners/Developers, Architects and Designers
                                </p>
                            </ReactTooltip>
                            <ReactTooltip
                                id={`tooltip_buyerType3`}
                                type={"info"}
                                effect={"solid"}
                                className={"custom-tooltip"}
                            >
                                <p>Homeowners/consumers</p>
                            </ReactTooltip>
                            <ul className={Styles["tooltip-checkboxes"]}>
                                <li>
                                    <Checkbox
                                        name='wholesale'
                                        label='Manufacturers / Distributors / Retailers'
                                        checked={wholesale}
                                        onChange={() => {
                                            setWholesale(!wholesale);
                                        }}
                                    />
                                    <button
                                        data-tip
                                        data-for={`tooltip_buyerType1`}
                                        className='info-tooltip'
                                        onClick={(e) => {
                                            e.preventDefault();
                                        }}
                                    >
                                        i
                                    </button>
                                </li>
                                <li>
                                    <Checkbox
                                        name='contractor'
                                        label='AEC Professionals'
                                        checked={contractor}
                                        onChange={() => {
                                            setContractor(!contractor);
                                        }}
                                    />
                                    <button
                                        data-tip
                                        data-for={`tooltip_buyerType2`}
                                        className='info-tooltip'
                                        onClick={(e) => {
                                            e.preventDefault();
                                        }}
                                    >
                                        i
                                    </button>
                                </li>
                                <li>
                                    <Checkbox
                                        name='retail'
                                        label='Homeowners / DIYers'
                                        checked={retail}
                                        onChange={() => {
                                            setRetail(!retail);
                                        }}
                                    />
                                    <button
                                        data-tip
                                        data-for={`tooltip_buyerType3`}
                                        className='info-tooltip'
                                        onClick={(e) => {
                                            e.preventDefault();
                                        }}
                                    >
                                        i
                                    </button>
                                </li>
                            </ul>
                        </div>

                        <div>
                            <h3>How quickly can you sell this product?</h3>

                            <Radio
                                name='availability'
                                value='regularly-stocked'
                                checked={availability === "regularly-stocked"}
                                onChange={() => {
                                    setAvailability("regularly-stocked");
                                }}
                                label='Regularly Stocked'
                            />

                            <Radio
                                name='availability'
                                value='lead-time-required'
                                checked={availability === "lead-time-required"}
                                onChange={() => {
                                    setAvailability("lead-time-required");
                                }}
                                label='Lead Time Required'
                            />
                        </div>
                        {!addForm && (
                            <div>
                                <h3>Product Status</h3>

                                <Radio
                                    name='status'
                                    value='available'
                                    checked={status === "available"}
                                    onChange={() => {
                                        setStatus("available");
                                    }}
                                    label='Available'
                                />

                                <Radio
                                    name='status'
                                    value='unavailable'
                                    checked={status === "unavailable"}
                                    onChange={() => {
                                        setStatus("unavailable");
                                    }}
                                    label='Unavailable'
                                />
                            </div>
                        )}
                        {locations.length > 1 &&
                            addForm &&
                            !setShowEditAllProductLocationForm && (
                                <div>
                                    <h3>
                                        Locations
                                        {!addFormLocationsAll && (
                                            <span
                                                className={
                                                    Styles.locationsAllNone
                                                }
                                                onClick={() => {
                                                    setAddFormLocationsAll(
                                                        true
                                                    );

                                                    setChosenLocations(
                                                        getLocationOptions().map(
                                                            (option) =>
                                                                option.value
                                                        )
                                                    );
                                                }}
                                            >
                                                Select All
                                            </span>
                                        )}
                                        {addFormLocationsAll && (
                                            <span
                                                className={
                                                    Styles.locationsAllNone
                                                }
                                                onClick={() => {
                                                    setAddFormLocationsAll(
                                                        false
                                                    );
                                                    setChosenLocations([]);
                                                }}
                                            >
                                                Clear
                                            </span>
                                        )}
                                    </h3>
                                    <BasicMulti
                                        options={getLocationOptions()}
                                        onChange={(e) => {
                                            let newChosenLocations = [];
                                            if (e) {
                                                e.forEach((option) => {
                                                    newChosenLocations.push(
                                                        option.value
                                                    );
                                                });
                                            }
                                            setChosenLocations(
                                                newChosenLocations
                                            );
                                        }}
                                        defaultValue={getDefaultLocationValue(
                                            productLocation
                                        )}
                                        value={getLocationOptions().filter(
                                            (option) =>
                                                chosenLocations.includes(
                                                    option.value
                                                )
                                        )}
                                    />
                                </div>
                            )}
                    </div>
                )}
            </form>
            {addProductMetaDataVisible && (
                <div className={`button-row ${className}`}>
                    {!showAddProductToLocationForm && !addForm && (
                        <Button
                            outline={true}
                            remove={true}
                            gray={true}
                            label={"Remove Product"}
                            onClick={handleDelete}
                        />
                    )}
                    <div className='main-pair'>
                        <Button
                            outline={true}
                            gray={true}
                            label={"Cancel"}
                            onClick={handleCancel}
                        />
                        <Button
                            outline={true}
                            label={addForm ? "Add Product" : "Save Changes"}
                            disabled={submitDisabled()}
                            onClick={handleSubmit}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProductLocationForm;
