// @flow
import React from "react";
import Styles from "./index.module.scss";
import ProductLocationForm from "../../product-location-form";
import type { ProductLocationType } from "../../../../../state/types/product-locations";

type PropsType = {
    productLocation: ProductLocationType,
    locationName: string,
    editProductLocationId: boolean,
    setEditProductLocationId: (number | null) => void,
};

const ProductLocation = (props: PropsType) => {
    const {
        productLocation,
        locationName,
        editProductLocationId,
        setEditProductLocationId,
    } = props;
    return (
        <li className={Styles.product}>
            {editProductLocationId !== productLocation.id && (
                <>
                    <p className={Styles["location"]}>{locationName}</p>
                    <button
                        className={Styles["edit-product-button"]}
                        onClick={() => {
                            setEditProductLocationId(productLocation.id);
                        }}
                        label={"Edit"}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="26"
                            height="26"
                            viewBox="0 0 26 26"
                        >
                            <path
                                d="M5.338,19.3L18.724,5.9l5.354,5.36-13.386,13.4ZM22.07,2.546a1.891,1.891,0,0,1,2.677,0l2.677,2.68a1.9,1.9,0,0,1,0,2.68l-2.008,2.01-5.354-5.36ZM1.991,28.009L4,20.638,9.354,26Z"
                                transform="translate(-2 -2)"
                            />
                        </svg>
                    </button>
                </>
            )}
            {editProductLocationId === productLocation.id && (
                <div className={Styles["productLocationFormWrapper"]}>
                    <ProductLocationForm
                        className={Styles["oneOfManyProductLocationEditForm"]}
                        productLocationName={locationName}
                        productLocation={productLocation}
                        setEditProductLocationId={setEditProductLocationId}
                    />
                </div>
            )}
        </li>
    );
};

export default ProductLocation;
