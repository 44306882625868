import React, { useEffect, useRef, useState, memo } from "react";
import ReactMapboxGl from "react-mapbox-gl";
import map_marker from "../../assets/map_marker.png";
import Styles from "./index.module.scss";

const mapStyle = "mapbox://styles/fsc-us/ck1qhhrf03p0a1cpvcszgrcs7";

const Map = ReactMapboxGl({
    accessToken: process.env.REACT_APP_MAPBOX_TOKEN,
    interactive: false,
});

const InteractiveMap = ReactMapboxGl({
    accessToken: process.env.REACT_APP_MAPBOX_TOKEN,
    interactive: true,
});

const LocationMap = memo(props => {
    const { edit, height, width, name, latitude, longitude, setMapRef, geocodestring } = props;
    const [center, setCenter] = useState(
        latitude && longitude ? [longitude, latitude] : [-96.4247, 31.51073],
    );
    const [zoom, setZoom] = useState(latitude && longitude ? [16] : [1]);
    const [lockGeocode, setLockGeocode] = useState(Boolean(latitude && longitude));
    const mapRef = useRef({ current: null });

    const MapFactory = edit ? InteractiveMap : Map;

    const dragStart = () => setLockGeocode(true);

    useEffect(() => {
        setCenter(latitude && longitude ? [longitude, latitude] : [-96.4247, 31.51073]);
        setZoom(latitude && longitude ? [16] : [1]);
    }, [longitude, latitude]);

    useEffect(() => {
        const geocode = async () => {
            if (lockGeocode) return;
            const result = await fetch(
                `https://api.mapbox.com/geocoding/v5/mapbox.places/${geocodestring}.json?&access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`,
            );
            const locations = await result.json();
            if (locations.features.length > 0) {
                if (mapRef.current && mapRef.current.flyTo) {
                    mapRef.current.flyTo({
                        center: locations.features[0].center,
                        zoom: 16,
                        speed: 1,
                    });
                }
            }
        };

        if (!lockGeocode && geocodestring) {
            geocode(geocodestring);
        }
    }, [geocodestring, lockGeocode]);

    return (
        <div className={Styles["map-container"]} style={{ width, height }}>
            {edit && lockGeocode && (
                <button
                    className={Styles.recalculate}
                    onClick={e => {
                        e.preventDefault();
                        setLockGeocode(false);
                    }}
                >
                    Re-calculate
                </button>
            )}
            <MapFactory
                ref={mapRef}
                style={mapStyle}
                onStyleLoad={map => {
                    setMapRef && setMapRef(map);
                    mapRef.current = map;
                }}
                containerStyle={{
                    height: "100%",
                    width: "100%",
                }}
                center={center}
                zoom={zoom}
                onDragStart={dragStart}
            />
            <img alt={name || "Location marker"} className={Styles.centerMarker} src={map_marker} />
        </div>
    );
});

export default LocationMap;
