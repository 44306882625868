// @flow
import React, { useContext } from "react";
import Styles from "./index.module.scss";
import type { LocationType } from "../../../../../state/types/locations";
import LocationForm from "../../location-form/";
import {
    deleteLocation,
    updateLocation,
} from "../../../../../state/actions/locations";
import LocationProduct from "./location-product";
import LocationMap from "../../../../../components/location-map";
import StoreContext from "../../../../../state/context/store";
import { LOCATION_TYPES_OPTIONS } from "./../../../../../state/constants";

type Props = {
    location: LocationType,
    editProductLocationId: null | number,
    setEditProductLocationId: (null | number) => void,
    editLocationId: null | number | "addForm",
    setEditLocationId: (null | number | "addForm") => void,
};

const Location = (props: Props) => {
    const {
        location,
        setEditLocationId,
        editLocationId,
        editProductLocationId,
        setEditProductLocationId,
    } = props;

    const [store, dispatch] = useContext(StoreContext);
    const { productLocations } = store.productLocationsReducer;
    const { user } = store;

    const speciesOptions = store.species;

    const theseProductLocations = productLocations.filter((pl) => {
        return pl.location === location.id && pl.status === "available";
    });

    const submitLocationHandler = (locationFormData: LocationType) => {
        if (locationFormData.title.length > 75) {
            window.alert(
                "Your location title needs to be less than 75 characters."
            );
        } else if (locationFormData.locationTypes.length === 0) {
            window.alert("Please enter a location type.");
        } else {
            setEditLocationId(null);
            updateLocation(dispatch, locationFormData);
        }
    };

    const deleteLocationHandler = (locationId) => {
        if (theseProductLocations.length === 0) {
            if (
                window.confirm(
                    "You are about to delete this location.  Proceed?"
                )
            ) {
                setEditLocationId(null);
                deleteLocation(dispatch, locationId);
            }
        } else {
            alert(
                "You must delete all associated products before deleting this location."
            );
        }
    };

    const speciesNameList = location.species
        .map((singleSpecies) => {
            return speciesOptions.find((option) => {
                return option.id === singleSpecies;
            }).name;
        })
        .join(", ");

    const locationTypesDisplayList = location.locationTypes
        .map((thisLocation) => {
            const locationType = LOCATION_TYPES_OPTIONS.find((option) => {
                return option.value === thisLocation;
            });
            if (locationType) {
                return locationType.label;
            }
            return "";
        })
        .join(", ");

    return (
        <li className={Styles.location}>
            {editLocationId !== location.id && (
                <div>
                    <button
                        className={Styles["edit-location"]}
                        onClick={(e) => {
                            e.preventDefault();
                            setEditLocationId(location.id);
                        }}
                    >
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='26'
                            height='26'
                            viewBox='0 0 26 26'
                        >
                            <path
                                d='M5.338,19.3L18.724,5.9l5.354,5.36-13.386,13.4ZM22.07,2.546a1.891,1.891,0,0,1,2.677,0l2.677,2.68a1.9,1.9,0,0,1,0,2.68l-2.008,2.01-5.354-5.36ZM1.991,28.009L4,20.638,9.354,26Z'
                                transform='translate(-2 -2)'
                            />
                        </svg>
                    </button>
                    <div className={Styles["location-metadata"]}>
                        {location.title && (
                            <div className={Styles.companyName}>
                                <p>{user.companyName}</p>
                                <p className={Styles.title}>{location.title}</p>
                            </div>
                        )}
                        <div
                            className={
                                location.title
                                    ? Styles["location-highlight-title-address"]
                                    : Styles.address
                            }
                        >
                            <p>
                                {location.streetAddress1}
                                <br />
                                {location.streetAddress2 && (
                                    <>
                                        {" "}
                                        {location.streetAddress2} <br />
                                    </>
                                )}
                                {location.city}, {location.provinceOrState}{" "}
                                {location.postalCode}
                                <br />
                                {location.country}
                            </p>
                        </div>

                        <div className={Styles["location-highlight"]}>
                            <h4>Location Types:</h4>
                            {locationTypesDisplayList !== "" && (
                                <p>{locationTypesDisplayList}</p>
                            )}
                            {locationTypesDisplayList === "" && (
                                <p className='error'>
                                    Please select a location type.
                                </p>
                            )}
                        </div>
                        <div className={Styles["location-highlight"]}>
                            <h4>Location Types:</h4>

                            {location.species.length > 0 && (
                                <p>{speciesNameList}</p>
                            )}
                            {location.species.length === 0 && (
                                <p className='error'>
                                    Please select at least one species.
                                </p>
                            )}
                        </div>

                        <div className={Styles["location-highlight"]}>
                            <h4>Sales Contact:</h4>
                            {location.salesContactName && (
                                <p>{location.salesContactName}</p>
                            )}
                            {!location.salesContactName && (
                                <p>{user.contactName}</p>
                            )}
                        </div>
                    </div>
                    <LocationMap
                        height='202px'
                        width='340px'
                        longitude={location.longitude}
                        latitude={location.latitude}
                        zoom={[16]}
                        edit={false}
                    />
                </div>
            )}
            {editLocationId === location.id && (
                <>
                    <LocationForm
                        location={location}
                        submitHandler={submitLocationHandler}
                        cancelHandler={() => setEditLocationId(null)}
                        deleteHandler={() => deleteLocationHandler(location.id)}
                        setEditProductLocationId={setEditProductLocationId}
                        editProductLocationId={editProductLocationId}
                    />
                </>
            )}

            {theseProductLocations.length > 0 &&
                store.user.status === "active" && (
                    <div className={Styles["products-by-location"]}>
                        <h4>Products at this location:</h4>
                        <ul className={Styles["product-list"]}>
                            {theseProductLocations
                                .filter((pl) => {
                                    return pl.location === location.id;
                                })
                                .map((pl) => (
                                    <LocationProduct
                                        className={
                                            Styles["productsByLocationEditForm"]
                                        }
                                        productLocation={pl}
                                        key={pl.id}
                                        productName={"sample product location"}
                                        editProductLocationId={
                                            editProductLocationId
                                        }
                                        setEditProductLocationId={
                                            setEditProductLocationId
                                        }
                                    />
                                ))}
                        </ul>
                    </div>
                )}
        </li>
    );
};

export default Location;
