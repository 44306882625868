import React from "react";
import ReactTooltip from "react-tooltip";
import makeAnimated from "react-select/animated";

import Select from "react-select";
import Styles from "./index.module.scss";

const animatedComponents = makeAnimated();

type Props = {
    options: Array<{ value: string, label: string }>,
    defaultValue?: ?Array<{ value: string, label: string }>,
    onChange: Function,
    isRequired: boolean,
    infoText?: string,
    infoHTML?: string,
    label: string,
    field: string,
    value: Array<{ value: string, label: string }>,
};

const BasicMulti = (props: Props) => {
    const {
        options,
        onChange,
        defaultValue,
        isRequired = false,
        infoText,
        infoHTML,
        label,
        field,
        value,
    } = props;
    return (
        <div className={Styles["dropdown-container"]}>
            <label
                onClick={(e) => {
                    e.preventDefault();
                }}
            >
                {label}
                {isRequired && (
                    <span className={"required-asterix"}>&nbsp;*</span>
                )}
                {Boolean(infoText || infoHTML) && (
                    <button
                        data-tip
                        data-for={`tooltip_` + field}
                        className='info-tooltip'
                    >
                        i
                    </button>
                )}
            </label>

            <ReactTooltip
                id={`tooltip_` + field}
                className={"custom-tooltip"}
                type={"info"}
                effect={"float"}
            >
                {infoText || infoHTML}
            </ReactTooltip>

            <Select
                isMulti
                closeMenuOnSelect={false}
                components={animatedComponents}
                name='colors'
                options={options}
                className='basic-multi-select'
                classNamePrefix='select'
                onChange={onChange}
                defaultValue={defaultValue}
                value={value}
            />
        </div>
    );
};
export default BasicMulti;
