// @flow
import React from "react";
import Styles from "./index.module.scss";
import ProductLocationForm from "../../../../products/product-location-form";
import type { ProductLocationType } from "../../../../../../state/types/product-locations";

type PropsType = {
    productLocation: ProductLocationType,
    productName: string,
    className: string,
    editProductLocationId: null | number,
    setEditProductLocationId: (null | number) => void,
};

const LocationProduct = (props: PropsType) => {
    const { productLocation, className, editProductLocationId, setEditProductLocationId } = props;

    return (
        <li
            className={Styles["product"]}
            onClick={() => {
                editProductLocationId !== productLocation.id &&
                    setEditProductLocationId(productLocation.id);
            }}
        >
            {editProductLocationId !== productLocation.id && (
                <div className={Styles["location-product-header"]}>
                    <h4>{productLocation.productName}</h4>
                    <button className={Styles["edit-product-button"]} label={"Edit"}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="26"
                            height="26"
                            viewBox="0 0 26 26"
                        >
                            <path
                                d="M5.338,19.3L18.724,5.9l5.354,5.36-13.386,13.4ZM22.07,2.546a1.891,1.891,0,0,1,2.677,0l2.677,2.68a1.9,1.9,0,0,1,0,2.68l-2.008,2.01-5.354-5.36ZM1.991,28.009L4,20.638,9.354,26Z"
                                transform="translate(-2 -2)"
                            />
                        </svg>
                    </button>
                </div>
            )}
            {editProductLocationId === productLocation.id && (
                <div className={Styles["edit-product"]}>
                    <h4>{productLocation.productName}</h4>
                    <ProductLocationForm
                        className={className}
                        productLocation={productLocation}
                        setEditProductLocationId={setEditProductLocationId}
                    />
                </div>
            )}
        </li>
    );
};

export default LocationProduct;
