// @flow
import { SECTION_ACTIONS } from "../constants";

import type { ActionType, SectionsReducerType, SectionsType } from "../types/section";

const initialState: SectionsType = [];

const SectionsReducer: SectionsReducerType = (
    state: SectionsType = initialState,
    { type, sections }: ActionType,
): SectionsType => {
    switch (type) {
        case SECTION_ACTIONS.FETCH_SECTIONS:
            return sections;
        default:
            return state;
    }
};

export { SectionsReducer };
