// @flow
let apiRoot = "";
if (process.env.NODE_ENV !== "production") {
    apiRoot = "http://127.0.0.1:8091/api/v2";
} else {
    // apiRoot = "https://admin.buildwithfsc.org/api/v2";
    apiRoot = "https://staging-admin.buildwithfsc.org/api/v2";
}

const config = {
    // Kim says, ...
    // I set site and secret keys in the domain test.fsc.org for
    // testing google captcha. In order to test, you need to get the
    // secret key to put in your your CWDB local.py, and make these changes ...
    // "set in your /etc/hosts ... "
    // 127.0.0.1       localhost test.fsc.org

    // Then, uncomment this line ...
    // apiRoot: 'http://test.fsc.org:8091/api/v2',
    // and comment out this one ...
    apiRoot: apiRoot,

    // Comment out this line to activate the recaptcha functionality. Then access the supplier-portal
    // at http://test.fsc.org:3000
    tier: "dev",

    // To simulate what the user will see on the /register page if Google thinks they
    // are a robot, change this line in state/actions/user.js from ...
    //                recaptchaVerified: json.recaptchaVerified,
    // to ...
    //                recaptchaVerified: false,
    // If for some reason the ReCaptcha is not working right and you want to simulate
    // it working, you could change the line to ...
    //                recaptchaVerified: true,
};

export default config;
