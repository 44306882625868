// @flow
import React, { useContext, useEffect, useState } from "react";
import Styles from "./index.module.scss";
import Box from "../../components/box";
import TextBox from "../../components/textbox";
import Button from "../../components/button";
import { resetApiError, resetPasswordAction } from "../../state/actions/user";
import logo from "../../assets/FSC-logo.png";
import StoreContext from "../../state/context/store";
import { withRouter } from "react-router-dom";

const ResetPassword = withRouter(({ history }) => {
    const [email, setEmail] = useState("");
    const [error, setError] = useState({});

    const [store, dispatch] = useContext(StoreContext);
    const { user } = store;
    const { passwordReset, apiError } = user;

    const validate = (): boolean => {
        if (email.length === 0) {
            setError(prevError => {
                return {
                    ...prevError,
                    email: "You must enter your Email Address",
                };
            });
            return false;
        }
        return true;
    };

    const reset = async (e: SyntheticEvent<HTMLElement>) => {
        if (validate()) {
            e.preventDefault();
            const response_json = await resetPasswordAction(email, dispatch);
            if (response_json !== undefined) {
                setError(prevError => {
                    return { ...response_json, ...prevError };
                });
            }
        }
    };

    useEffect(() => {
        setError(prevError => {
            return { ...apiError, ...prevError };
        });
    }, [apiError]);

    const formFields = ["email"];
    const nonFieldErrors = Object.keys(error)
        .filter(field => !formFields.includes(field))
        .map(key => {
            return <div className="error">{error[key]}</div>;
        });
    const formErrorStyle = { border: "2px solid red" };

    const resetPage = (
        <div className={`${Styles.reset} box_wrapper`}>
            <div className="logo-wrapper">
                <img src={logo} className="logo" alt="Forest Stewardship Council" />
                <span className="logo-site-text">
                    Supplier
                    <br />
                    Portal
                </span>
            </div>
            <Box className={Styles.reset_box}>
                <h1>Reset Password</h1>
                <p className="sub-head">
                    Enter the email address used to create your account, we'll send you a new
                    password
                </p>

                <form>
                    <TextBox
                        style={error.email && formErrorStyle}
                        value={email}
                        onChange={({ target }: SyntheticInputEvent<HTMLInputElement>) => {
                            setEmail(target.value);
                            setError(prevError => {
                                const { email, ...remaining } = prevError;
                                return remaining;
                            });
                        }}
                        autoComplete="email"
                        placeholder="Email Address"
                    />
                    {error.email && <div className="error">{error.email}</div>}
                    {nonFieldErrors}
                </form>

                <div className="button-row">
                    <Button
                        outline={true}
                        gray={true}
                        label={"Cancel"}
                        onClick={() => {
                            resetApiError(dispatch);
                            history.push("/");
                        }}
                    />
                    <Button label="Send Email" onClick={reset} />
                </div>
            </Box>
        </div>
    );

    const postResetPage = (
        <div className={`${Styles.reset} box_wrapper`}>
            <div className="logo-wrapper">
                <img src={logo} className="logo" alt="Forest Stewardship Council" />
                <span className="logo-site-text">
                    Supplier
                    <br />
                    Portal
                </span>
            </div>
            <Box className={Styles.reset_box}>
                <h1>Email sent</h1>
                <p>Please check your email to confirm your password reset request.</p>
            </Box>
        </div>
    );

    return passwordReset ? postResetPage : resetPage;
});

export default ResetPassword;
