// @flow
import type {
    ActionType,
    ProductLocationsContextType,
    ProductLocationsType,
    ProductLocationType,
} from "../types/product-locations";

const initialState: ProductLocationsContextType = {
    productLocations: [],
};

const deleteProductLocation = (
    productLocations: ProductLocationsType,
    productLocation: ProductLocationType,
): ProductLocationsType => {
    const idx = productLocations.findIndex(
        newProductLocation => newProductLocation.id === productLocation.id,
    );
    productLocations.splice(idx, 1);
    return [...productLocations];
};

// const addNewProductLocations = (
//     productLocations: ProductLocationsType,
//     newProductLocations: ProductLocationsType,
// ): ProductLocationsType => {
//     // make a copy
//     let productLocationsCopy: ProductLocationsType = [...productLocations];
//     // add the new product locations
//     productLocationsCopy.push(newProductLocations);
//     return productLocationsCopy;
// };

const updateProductLocations = (
    productLocations: ProductLocationsType,
    productLocation: ProductLocationType,
): ProductLocationsType => {
    let newProductLocations: ProductLocationsType = [...productLocations];
    const {
        id,
        product,
        location,
        status,
        retail,
        contractor,
        wholesale,
        availability,
    } = productLocation;

    const idx = newProductLocations.findIndex(newProductLocation => newProductLocation.id === id);

    if (idx > -1) {
        newProductLocations[idx] = {
            ...newProductLocations[idx],
            id,
            product,
            location,
            status,
            retail,
            contractor,
            wholesale,
            availability,
        };
    } else {
        newProductLocations.push(productLocation);
    }
    return newProductLocations;
};

const ProductLocationsReducer = (
    state: ProductLocationsContextType = initialState,
    action: ActionType,
): ProductLocationsContextType => {
    switch (action.type) {
        case "API_ERROR":
            return {
                ...state,
                productLocations: state.productLocations,
                error: action.error,
            };
        case "UPDATE_PRODUCT_LOCATION":
            return {
                ...state,
                productLocations: updateProductLocations(
                    state.productLocations,
                    action.productLocation,
                ),
            };
        case "ADD_PRODUCT_LOCATIONS":
            return {
                ...state,
                productLocations: action.productLocations,
            };
        case "FETCH_PRODUCT_LOCATIONS":
            return {
                ...state,
                productLocations: action.productLocations,
            };
        case "REMOVE_PRODUCT_LOCATION":
            return {
                ...state,
                productLocations: deleteProductLocation(
                    state.productLocations,
                    action.productLocation,
                ),
            };
        default:
            return {
                ...state,
                error: { nonFieldErrors: ["Unexpected Action"] },
            };
    }
};

export { ProductLocationsReducer };
