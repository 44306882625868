// @flow
import React, { useContext, useEffect, useState } from "react";
import Styles from "./index.module.scss";

import {
    CA_PROVINCE_OPTIONS,
    MX_STATE_OPTIONS,
    US_STATE_OPTIONS,
    LOCATION_TYPES_OPTIONS,
} from "../../../../state/constants";
import LocationTextBox from "../../../../components/location-textbox";
import BasicSelect from "../../../../components/basic-select";
import Button from "../../../../components/button";
import type { LocationType } from "../../../../state/types/locations";
import BasicMulti from "../../../../components/multi-select";
import StoreContext from "../../../../state/context/store";
import Checkbox from "../../../../components/checkbox";

type Props = {
    location?: LocationType,
    submitHandler: (LocationType) => void,
    cancelHandler: () => void,
    deleteHandler?: (number) => void,
};

const LocationForm = (props: Props) => {
    const [store] = useContext(StoreContext);
    const speciesOptions = store.species;
    const { location, submitHandler, cancelHandler, deleteHandler } = props;
    const addForm = !(location && location.id);

    // local state properties for the form
    const [title, setTitle] = useState(location ? location.title : "");
    const [streetAddress1, setStreetAddress1] = useState(
        location ? location.streetAddress1 : ""
    );
    const [streetAddress2, setStreetAddress2] = useState(
        location ? location.streetAddress2 : ""
    );
    const [city, setCity] = useState(location ? location.city : "");
    const [provinceOrState, setProvinceOrState] = useState(
        location ? location.provinceOrState : ""
    );
    const [country, setCountry] = useState(location ? location.country : "US");
    const [prevCountry, setPrevCountry] = useState(
        location ? location.country : "US"
    );
    const [postalCode, setPostalCode] = useState(
        location ? location.postalCode : ""
    );
    const [provinceOptions, setProvinceOptions] = useState([]);
    const [provinceLabel, setProvinceLabel] = useState("");
    const [postalCodeLabel, setPostalCodeLabel] = useState("");
    const [salesContactName, setSalesContactName] = useState(
        location ? location.salesContactName : ""
    );
    const [salesContactEmail, setSalesContactEmail] = useState(
        location ? location.salesContactEmail : ""
    );
    const [salesContactPhone, setSalesContactPhone] = useState(
        location ? location.salesContactPhone : ""
    );

    const [species, setSpecies] = useState(location ? location.species : []);
    const [locationTypes, setLocationTypes] = useState(
        location ? location.locationTypes : []
    );
    const [sameSalesContact, setSameSalesContact] = useState(
        location ? location.sameSalesContact : false
    );
    const [emailErrorText, setEmailErrorText] = useState("");

    const isSubmitDisabled = (): boolean => {
        const requiredFieldValues: string[] = [
            streetAddress1,
            city,
            provinceOrState,
            country,
            postalCode,
        ];

        if (!sameSalesContact) {
            requiredFieldValues.push(salesContactName);
            requiredFieldValues.push(salesContactPhone);
            requiredFieldValues.push(salesContactEmail);
        }
        if (
            requiredFieldValues.includes("") ||
            locationTypes.length === 0 ||
            species.length === 0
        ) {
            return true;
        }
        if (emailErrorText) {
            return true;
        }
        return false;
    };

    const getLocationFormData = () => {
        let temp = {
            streetAddress1,
            streetAddress2,
            city,
            provinceOrState,
            country,
            postalCode,
            species,
            locationTypes,
            salesContactName,
            salesContactPhone,
            salesContactEmail,
            sameSalesContact,
            title,
        };

        if (location && sameSalesContact) {
            temp.salesContactName = "";
            temp.salesContactPhone = "";
            temp.salesContactEmail = "";
        }
        if (location && location.id) {
            temp.id = location.id;
        }
        return temp;
    };

    const defaultSpeciesOptions = speciesOptions
        .filter((option) => {
            return species.includes(option.id);
        })
        .map((option) => {
            return { value: option.id, label: option.name };
        });

    const defaultLocationTypesOptions = LOCATION_TYPES_OPTIONS.filter(
        (option) => {
            return locationTypes.includes(option.value);
        }
    ).map((option) => {
        return { value: option.value, label: option.label };
    });

    const locationTypeInfoHTML = {
        html: (
            <ul className={Styles.locationTypeInfoHTML}>
                <li className={Styles.locationTypeInfoHTML}>
                    <div className={Styles["location-list-item-header"]}>
                        Retail
                    </div>
                    <ul>
                        <li>Flooring, Furniture or Mass/DIY retailer</li>
                    </ul>
                </li>

                <li className={Styles.locationTypeInfoHTML}>
                    <div className={Styles["location-list-item-header"]}>
                        Specialty Woodworking
                    </div>
                    <ul>
                        <li>Cabinet shop</li>
                        <li>Architectural millworker</li>
                        <li>Furniture maker</li>
                    </ul>
                </li>

                <li className={Styles.locationTypeInfoHTML}>
                    <div className={Styles["location-list-item-header"]}>
                        Wholesale Distribution
                    </div>
                    <ul>
                        <li>Flooring, Veneer or Hardwood distributor</li>
                        <li>Concentration Yard, Reload, Lumber Yard</li>
                    </ul>
                </li>

                <li className={Styles.locationTypeInfoHTML}>
                    <div className={Styles["location-list-item-header"]}>
                        Secondary Manufacturing
                    </div>
                    <ul>
                        <li>Flooring mill</li>
                        <li>
                            Furniture, Moulding, Cabinet or Engineered wood
                            products
                        </li>
                        manufacturer
                    </ul>
                </li>

                <li className={Styles.locationTypeInfoHTML}>
                    <div className={Styles["location-list-item-header"]}>
                        Primary Manufacturing
                    </div>
                    <ul>
                        <li>Sawmill</li>
                        <li>
                            Chip/fiber, Veneer, hardwood, structural plywood,
                            OSB or MDF/particle board mill
                        </li>
                    </ul>
                </li>
            </ul>
        ),
    };

    const speciesInfoHTML = {
        html: <span>Species not here? Contact us!</span>,
    };

    const sameSalesContactLabel = sameSalesContact
        ? `The sales contact for this location is ${store.user.contactName}`
        : `Make the sales contact for this location ${store.user.contactName}`;

    useEffect(() => {
        if (prevCountry !== country) {
            setProvinceOrState("");
        }
        if (!country || country === "US") {
            setProvinceOptions(US_STATE_OPTIONS);
            setProvinceLabel("State");
            setPostalCodeLabel("Zipcode");
        } else if (country === "CA") {
            setProvinceOptions(CA_PROVINCE_OPTIONS);
            setProvinceLabel("Province");
            setPostalCodeLabel("Postal Code");
        } else if (country === "MX") {
            setProvinceOptions(MX_STATE_OPTIONS);
            setProvinceLabel("State");
            setPostalCodeLabel("Postal Code");
        } else {
            console.log("found unexpected country:", country);
        }
        setPrevCountry(country);
    }, [country, prevCountry]);

    return (
        <div className={Styles["locations-form"]}>
            <form>
                <fieldset>
                    <div className='form-group'>
                        <div className='form-row'>
                            <LocationTextBox
                                autoComplete=''
                                field='title'
                                placeholder='Location Title'
                                setter={setTitle}
                                value={title}
                                isRequired={false}
                            />
                        </div>
                        <div className='form-row'>
                            <LocationTextBox
                                autoComplete='address'
                                field='streetAddress1'
                                placeholder='Address Line 1'
                                setter={setStreetAddress1}
                                value={streetAddress1}
                            />
                            <LocationTextBox
                                autoComplete='off'
                                field='streetAddress2'
                                placeholder='Address Line 2'
                                setter={setStreetAddress2}
                                value={streetAddress2}
                                isRequired={false}
                            />
                        </div>

                        <div className='form-row'>
                            <LocationTextBox
                                autoComplete='city'
                                field='city'
                                placeholder='City'
                                setter={setCity}
                                value={city}
                            />
                            <BasicSelect
                                options={provinceOptions}
                                field='provinceOrState'
                                label={provinceLabel}
                                value={provinceOrState}
                                setter={setProvinceOrState}
                                isRequired={true}
                            />
                            <BasicSelect
                                options={[
                                    { value: "CA", label: "Canada" },
                                    { value: "US", label: "United States" },
                                    { value: "MX", label: "Mexico" },
                                ]}
                                defaultValue={{
                                    value: "US",
                                    label: "United States",
                                }}
                                field='country'
                                label={"Country"}
                                value={country}
                                setter={setCountry}
                                isRequired={true}
                            />
                            <LocationTextBox
                                autoComplete='zipcode'
                                field='postalCode'
                                placeholder={postalCodeLabel}
                                setter={setPostalCode}
                                value={postalCode}
                            />
                        </div>
                        <div className='form-row'>
                            <Checkbox
                                name='sameSalesContact'
                                label={sameSalesContactLabel}
                                checked={sameSalesContact}
                                onChange={(e) => {
                                    setSameSalesContact(!sameSalesContact);
                                    if (!sameSalesContact) {
                                        setEmailErrorText("");
                                    }
                                }}
                            />
                        </div>
                        {!sameSalesContact && (
                            <div className='form-row'>
                                <LocationTextBox
                                    autoComplete={"off"}
                                    field='salesContactName'
                                    placeholder={"Sales Contact Name"}
                                    setter={setSalesContactName}
                                    value={salesContactName}
                                />
                                <LocationTextBox
                                    autoComplete={"off"}
                                    field='salesContactEmail'
                                    placeholder={"Sales Contact Email"}
                                    setter={setSalesContactEmail}
                                    value={salesContactEmail}
                                    errorText={emailErrorText}
                                    validate={(e) => {
                                        const email_regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/i;

                                        if (e.target.value === "") {
                                            setEmailErrorText(
                                                "This field is required."
                                            );
                                        } else if (
                                            !email_regex.test(e.target.value)
                                        ) {
                                            setEmailErrorText(
                                                "This email address appears invalid.  Please correct."
                                            );
                                        } else {
                                            setEmailErrorText("");
                                            return true;
                                        }
                                        return false;
                                    }}
                                    errorTextSetter={setEmailErrorText}
                                />

                                <LocationTextBox
                                    autoComplete={"off"}
                                    field='salesContactName'
                                    placeholder={"Sales Contact Phone"}
                                    setter={setSalesContactPhone}
                                    value={salesContactPhone}
                                />
                            </div>
                        )}

                        <div className='form-row'>
                            <div>
                                <BasicMulti
                                    options={LOCATION_TYPES_OPTIONS}
                                    defaultValue={defaultLocationTypesOptions}
                                    field='locationTypes'
                                    value={defaultLocationTypesOptions}
                                    label='What Type of Location is this?'
                                    setter={setLocationTypes}
                                    isRequired={true}
                                    infoHTML={locationTypeInfoHTML.html}
                                    onChange={(e) => {
                                        let newLocationTypes = [];
                                        if (e) {
                                            e.forEach((option) => {
                                                newLocationTypes.push(
                                                    option.value
                                                );
                                            });
                                        }
                                        setLocationTypes(newLocationTypes);
                                    }}
                                />
                            </div>

                            <BasicMulti
                                field='species'
                                label='Which species do you generally stock?'
                                defaultValue={defaultSpeciesOptions}
                                isRequired={true}
                                infoHTML={speciesInfoHTML.html}
                                options={speciesOptions.map((option) => {
                                    return {
                                        value: option.id,
                                        label: option.name,
                                    };
                                })}
                                onChange={(e) => {
                                    let newSpecies = [];
                                    if (e) {
                                        e.forEach((option) => {
                                            newSpecies.push(option.value);
                                        });
                                    }
                                    setSpecies(newSpecies);
                                }}
                            />
                        </div>
                    </div>
                </fieldset>
            </form>
            <div className='button-row'>
                {location && deleteHandler && (
                    <Button
                        gray={true}
                        outline={true}
                        disabled={false}
                        remove={true}
                        label={"Delete"}
                        onClick={(e) => {
                            e.preventDefault();
                            deleteHandler(location.id);
                        }}
                    />
                )}

                <Button
                    gray={true}
                    outline={true}
                    disabled={false}
                    label={"Cancel"}
                    onClick={(e) => {
                        e.preventDefault();
                        cancelHandler();
                    }}
                />
                <Button
                    fullWidth={false}
                    disabled={isSubmitDisabled()}
                    label={addForm ? "Add" : "Update"}
                    onClick={(e) => {
                        e.preventDefault();
                        submitHandler(getLocationFormData());
                    }}
                />
            </div>
        </div>
    );
};

export default LocationForm;
