// @flow
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Styles from "./index.module.scss";
import Box from "../../components/box";
import TextBox from "../../components/textbox";
import Button from "../../components/button";
import logo from "../../assets/FSC-logo.png";
import { loginAction } from "../../state/actions/user";
import StoreContext from "../../state/context/store";

type Props = {
    location: {
        hash: string,
        pathname: string,
        search: string,
        state?: {
            from: {
                pathname: string,
            },
        },
    },
    history: {
        push: any => void,
    },
};

const Login = (props: Props) => {
    const [store, dispatch] = useContext(StoreContext);
    const { user } = store;
    const { authenticated } = user;
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    let [error, setError] = useState({});

    useEffect(() => {
        if (authenticated) {
            const { from } = props.location.state || {
                from: { pathname: "/" },
            };
            props.history.push(from);
        }
    }, [authenticated, props.history, props.location.state]);

    const validate = (): boolean => {
        let valid = true;
        if (email.length === 0) {
            setError(prevError => {
                return {
                    ...prevError,
                    email: "You must enter your Email Address",
                };
            });
            valid = false;
        }
        if (password.length === 0) {
            setError(prevError => {
                return {
                    ...prevError,
                    password: "You must enter your Password",
                };
            });
            valid = false;
        }
        return valid;
    };

    const login = async (e: SyntheticEvent<HTMLElement>) => {
        if (validate()) {
            e.preventDefault();
            const response_json = await loginAction(email, password, dispatch);
            if (response_json !== undefined) {
                setError(prevError => {
                    return { ...response_json, ...prevError };
                });
            }
        }
    };

    useEffect(() => {
        setEmail("");
        setPassword("");
    }, []);

    const formFields = ["email", "password"];
    const nonFieldErrors = Object.keys(error)
        .filter(field => !formFields.includes(field))
        .map(key => {
            return (
                <div className="error" key={key}>
                    {error[key]}
                </div>
            );
        });

    const formErrorStyle = { border: "2px solid red" };

    return (
        <div className={`${Styles.login} box_wrapper`}>
            <div className="logo-wrapper">
                <img
                    src={logo}
                    className="logo"
                    alt="Forest Stewardship Council"
                />
                <span className="logo-site-text">
                    Supplier
                    <br />
                    Portal
                </span>
            </div>
            <Box className={Styles.login_box}>
                <h1>Login to FSC Supplier Portal</h1>
                <p className="sub-head">
                    or <Link to="/register">Create an Account</Link>
                </p>
                <form>
                    <TextBox
                        style={error.email && formErrorStyle}
                        value={email}
                        onFocus={e => {
                            setError(prevError => {
                                const {
                                    email,
                                    nonFieldErrors,
                                    ...remaining
                                } = prevError;
                                return remaining;
                            });
                        }}
                        onChange={({
                            target,
                        }: SyntheticInputEvent<HTMLInputElement>) => {
                            setEmail(target.value);
                            setError(prevError => {
                                const {
                                    email,
                                    nonFieldErrors,
                                    ...remaining
                                } = prevError;
                                return remaining;
                            });
                        }}
                        autoComplete="email"
                        placeholder="Work Email"
                        onKeyUp={e => {
                            if (e.keyCode === 13) {
                                login(e);
                            }
                        }}
                    />
                    {error.email && <div className="error">{error.email}</div>}
                    <TextBox
                        style={error.password && formErrorStyle}
                        value={password}
                        type="password"
                        onFocus={e => {
                            setError(prevError => {
                                const {
                                    password,
                                    nonFieldErrors,
                                    ...remaining
                                } = prevError;
                                return remaining;
                            });
                        }}
                        onChange={({
                            target,
                        }: SyntheticInputEvent<HTMLInputElement>) => {
                            setPassword(target.value);
                            setError(prevError => {
                                const {
                                    password,
                                    nonFieldErrors,
                                    ...remaining
                                } = prevError;
                                return remaining;
                            });
                        }}
                        autoComplete="current-password"
                        placeholder="Password"
                        onKeyUp={e => {
                            if (e.keyCode === 13) {
                                login(e);
                            }
                        }}
                    />
                    {error.password && (
                        <div className="error">{error.password}</div>
                    )}
                    {nonFieldErrors}
                </form>

                <Button fullWidth label="Log In" onClick={login} />

                <Link className={Styles.forgot_password} to="/reset-password">
                    Forgot password?
                </Link>
            </Box>
        </div>
    );
};

export default Login;
