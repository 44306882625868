// @flow
import React, { useContext } from "react";
import Styles from "./index.module.scss";
import Box from "../../components/box";
import Button from "../../components/button";
import { activateAccountAction } from "../../state/actions/user";
import StoreContext from "../../state/context/store";
import logo from "../../assets/FSC-logo.png";
import { Link } from "react-router-dom";

type Props = { match: any };

const ActivateAccount = (props: Props) => {
    const [store, dispatch] = useContext(StoreContext);
    const { user } = store;
    const { isEmailVerified, apiError } = user;

    const uid = props.match.params.uid;
    const token = props.match.params.token;
    const activateAccount = (e: SyntheticEvent<HTMLElement>) => {
        activateAccountAction(uid, token, dispatch);
    };

    let errorMsg = "";
    if (apiError) {
        errorMsg = Object.values(apiError).join("");
    }

    return (
        <div className={`${Styles.register} box_wrapper`}>
            <div className="logo-wrapper">
                <img
                    src={logo}
                    className="logo"
                    alt="Forest Stewardship Council"
                />
                <span className="logo-site-text">
                    Supplier
                    <br />
                    Portal
                </span>
            </div>
            <Box className={Styles.register_box}>
                {!isEmailVerified && (
                    <>
                        <h1>Activate Account</h1>
                        <Button label="Activate" onClick={activateAccount} />
                        {errorMsg && (
                            <div className={Styles.error}>{errorMsg}</div>
                        )}
                    </>
                )}
                {isEmailVerified && (
                    <>
                        <h2>Your account is now activated.</h2>
                        <br />
                        <p>
                            You may <Link to="/">continue</Link> setting up your
                            profile at this time.
                        </p>
                    </>
                )}
            </Box>
        </div>
    );
};

export default ActivateAccount;
