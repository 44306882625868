// @flow
import React from "react";
import TextBox from "../textbox";

type Props = {
    field: string,
    onChange?: (e: SyntheticInputEvent<HTMLInputElement>) => any,
    placeholder?: string,
    autoComplete?: string,
    disabled?: boolean,
    infoText?: string,
    value: string,
    setter: Function,
    isRequired?: boolean,
    errorText?: string,
    errorTextSetter?: string => void,
    validate?: (e: SyntheticInputEvent<HTMLInputElement>) => boolean,
};

export const LocationTextBox = (props: Props) => {
    const {
        field,
        placeholder,
        autoComplete,
        infoText,
        value,
        setter,
        validate,
        isRequired = true,
        errorText = "",
        errorTextSetter,
    } = props;
    const formErrorStyle = { border: "2px solid red" };
    return (
        <div>
            <label>
                {placeholder}
                {isRequired && <span className={"required-asterix"}>&nbsp;*</span>}
                {infoText && (
                    <span>
                        &nbsp;<button>i</button>
                    </span>
                )}
            </label>
            <TextBox
                style={errorText && formErrorStyle}
                type="text"
                value={value}
                onFocus={() => {
                    errorTextSetter && errorTextSetter("");
                }}
                onChange={e => {
                    setter(e.target.value);
                }}
                onBlur={e => {
                    e.target.value = e.target.value.trim();
                    setter(e.target.value);
                    validate && validate(e);
                }}
                placeholder={placeholder || ""}
                autoComplete={autoComplete}
                name={field}
                classSuffix={field}
                error={false}
                disabled={false}
            />
            {errorText && <div className="error">{errorText}</div>}
        </div>
    );
};

export default LocationTextBox;
