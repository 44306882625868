// @flow
import config from "../../config";
import { FETCH_SECTIONS } from "../constants";
import type { Dispatch } from "../types/section";
import { buildHeaders } from "../../lib/helpers";

// ActionType should maybe go where any is.
// http://frantic.im/using-redux-with-flow
export const getSections = async (dispatch: Dispatch): Promise<any> => {
    const token = window.localStorage.getItem("token");
    const headers = buildHeaders(token);
    const result = await fetch(`${config.apiRoot}/section`, {
        method: "GET",
        headers: headers,
    });
    const sections = await result.json();
    return dispatch({
        type: FETCH_SECTIONS,
        sections,
    });
};
