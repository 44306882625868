// @flow
import { USER_ACTIONS } from "../constants";
import type { ActionType, UserType, UserReducerType } from "../types/user";

const token = window.localStorage.getItem("token"); // ex. aef177ebce31e53e3c50ef1a02fa638ec58c6539

const initialState = {
    apiError: {},
    authenticated: token ? true : false,
    certifiedSince: null,
    fscLicenseCode: "",
    city: "",
    companyName: "",
    contactEmail: "",
    contactName: "",
    contactPhone: "",
    country: "",
    postalCode: "",
    provinceOrState: "",
    species: [],
    status: "",
    streetAddress1: "",
    streetAddress2: "",
    token: token || null, // before log in this is undefined...
    webAddress: "",
    confirmedNewPassword: false, // a little spooky. -- this value doesn't seem like it should be here
    passwordReset: false, // a little spooky. -- this value doesn't seem like it should be here
    field: "",
    recaptchaVerified: false,
    value: "",
    companyInfoCompleted: false,
    isEmailVerified: false,
};

const UserReducer = (
    state: UserType = initialState,
    {
        apiError,
        // authenticated,
        certifiedSince,
        fscLicenseCode,
        city,
        companyInfoCompleted,
        companyName,
        // confirmedNewPassword,
        contactEmail,
        contactName,
        contactPhone,
        country,
        field,
        // passwordReset,
        postalCode,
        provinceOrState,
        species,
        status,
        streetAddress1,
        streetAddress2,
        type,
        token,
        value,
        recaptchaVerified,
        webAddress,
        isEmailVerified,
    }: ActionType
): UserReducerType => {
    switch (type) {
        case USER_ACTIONS.SET_TOKEN:
            return {
                ...state,
                token,
                authenticated: true,
            };
        case USER_ACTIONS.API_ERROR:
            const newAPIError = { ...state.apiError, ...apiError };
            return {
                ...state,
                apiError: newAPIError,
            };
        case USER_ACTIONS.LOGIN_USER:
            window.localStorage.setItem("token", token);
            return {
                ...state,
                authenticated: true,
                token,
            };
        case USER_ACTIONS.ACTIVATE:
            return {
                ...state,
                isEmailVerified: true,
            };
        case USER_ACTIONS.LOGOUT_USER:
            window.localStorage.clear();
            return {
                ...initialState,
                token: null,
                authenticated: false,
                companyInfoCompleted: false,
            };
        case USER_ACTIONS.SET_ME:
            return {
                ...state,
                certifiedSince,
                fscLicenseCode,
                city,
                companyInfoCompleted,
                companyName,
                contactEmail,
                contactName,
                contactPhone,
                country,
                postalCode,
                provinceOrState,
                species,
                status,
                streetAddress1,
                streetAddress2,
                webAddress,
                isEmailVerified,
            };
        case USER_ACTIONS.PASSWORD_RESET:
            return {
                ...state,
                passwordReset: true,
            };
        case USER_ACTIONS.PASSWORD_RESET_CONFIRM:
            return {
                ...state,
                confirmedNewPassword: true,
            };
        case USER_ACTIONS.PASSWORD_CHANGE:
            return {
                ...state,
                passwordChanged: true,
            };
        case USER_ACTIONS.UPDATE_USER_FIELD:
            // if (field === "country" && state.country !== value) {
            //     provinceOrState = "";
            // } else if (field === "provinceOrState" && state.provinceOrState !== value) {
            //     provinceOrState = value;
            // } else {
            //     provinceOrState = state.provinceOrState;
            // }
            delete state.apiError[field];
            return {
                ...state,
                // $FlowFixMe
                [field]: value,
                provinceOrState,
                companyInfoCompleted,
                // $FlowFixMe
                apiError: { ...state.apiError },
            };

        case USER_ACTIONS.RECAPTCHA_VERIFY:
            return {
                recaptchaVerified,
            };
        case USER_ACTIONS.RESET_API_ERROR:
            return {
                ...state,
                apiError: {},
            };

        default:
            return {
                ...state,
                jsError: "Unexpected Action",
            };
    }
};

export { UserReducer };
