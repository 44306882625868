// @flow
import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./app";
import * as serviceWorker from "./serviceWorker";

const root = document.getElementById("root");
if (root) {
    ReactDOM.render(<App />, root);
} else {
    throw new Error("Root element is missing");
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
