import { combineReducers } from "redux";
import { AnalyticsReducer } from "./analytics";
import { LocationsReducer } from "./locations";
import { ProductLocationsReducer } from "./product-locations";
import { UserReducer } from "./user";
import { SectionsReducer } from "./section";
import { SpeciesReducer } from "./species";

const allReducers = combineReducers({
    sections: SectionsReducer,
    analyticsReducer: AnalyticsReducer,
    locationsReducer: LocationsReducer,
    productLocationsReducer: ProductLocationsReducer,
    user: UserReducer,
    species: SpeciesReducer,
});

const rootReducer = (state, action) => {
    return allReducers(state, action);
};

export default rootReducer;
