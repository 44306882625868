// @flow
import type { ActionType, AnalyticsContextType } from "../types/analytics";

const initialState: AnalyticsContextType = {
    analytics: null,
};

const AnalyticsReducer = (
    state: AnalyticsContextType = initialState,
    action: ActionType,
): AnalyticsContextType => {
    switch (action.type) {
        case "API_ERROR":
            return {
                ...state,
                analytics: state.analytics,
                error: action.error,
            };
        case "FETCH_ANALYTICS":
            return {
                ...state,
                analytics: action.payload,
            };
        default:
            return {
                ...state,
                error: { nonFieldErrors: ["Unexpected Action"] },
            };
    }
};

export { AnalyticsReducer };
