// @flow
import React, { useContext, useEffect, useState } from "react";
import { updateUserField, patchUserField } from "../../state/actions/user";
import TextBox from "../textbox";
import StoreContext from "../../state/context/store";
import ReactTooltip from "react-tooltip";

type CompanyInfoProps = {
    field: string,
    onChange?: (e: SyntheticInputEvent<HTMLInputElement>) => any,
    placeholder?: string,
    autoComplete?: string,
    label?: string,
    disabled?: boolean,
    infoText?: string,
    isRequired?: boolean,
    helpText?: string,
    transform?: (text: string) => string,
};

export const CompanyInfoTextBox = (props: CompanyInfoProps) => {
    const {
        field,
        placeholder,
        label,
        autoComplete,
        disabled = false,
        infoText,
        isRequired = true,
        helpText,
        transform,
    } = props;
    const [store, dispatch] = useContext(StoreContext);
    const { user } = store;
    const { token, apiError, ...userContext } = user;

    const [errorText, setErrorText] = useState(false);

    const labelText = label ? label : placeholder;

    useEffect(() => {
        if (apiError && field in apiError && apiError[field].length > 0) {
            setErrorText(apiError[field]);
        } else {
            setErrorText(false);
        }
    }, [apiError, field]);

    return (
        <div>
            <label
                onClick={e => {
                    e.preventDefault();
                }}
            >
                {labelText}
                {isRequired && (
                    <span className={"required-asterix"}>&nbsp;*</span>
                )}
                {Boolean(infoText) && (
                    <>
                        <button
                            data-tip
                            data-for={`tooltip_` + field}
                            className="info-tooltip"
                        >
                            i
                        </button>
                    </>
                )}
            </label>
            {Boolean(infoText) && (
                <ReactTooltip
                    id={`tooltip_` + field}
                    type={"info"}
                    effect={"solid"}
                    className={"custom-tooltip"}
                    place={"right"}
                >
                    {infoText}
                </ReactTooltip>
            )}

            <TextBox
                type="text"
                value={userContext[field]}
                onChange={e => {
                    e.preventDefault();
                    updateUserField(dispatch, field, e.target.value);
                }}
                onBlur={e => {
                    e.preventDefault();
                    let newValue = e.target.value;
                    newValue = newValue.trim();
                    newValue = newValue.replace(/\s{2,10}/g, " ");

                    if (newValue === "" || newValue === " ") {
                        newValue = e.target.value;
                    }
                    if (transform) {
                        newValue = transform(newValue);
                    }
                    updateUserField(dispatch, field, newValue);

                    // if (newValue !== e.target.value) {
                    patchUserField(token, dispatch, field, newValue);
                    // }
                }}
                placeholder={placeholder || ""}
                autoComplete={autoComplete}
                name={field}
                classSuffix={field}
                error={Boolean(errorText)}
                disabled={disabled}
            />
            {helpText && <div className={"helpText"}>{helpText}</div>}
            {errorText && <div className="error">{errorText}</div>}
        </div>
    );
};

export default CompanyInfoTextBox;
