// @flow
import React from "react";
import Styles from "./index.module.scss";

const Radio = (props: Object) => {
  const { name, label, onChange, checked } = props;
  return (
      <label className={Styles.radio}>
          <input
              name={name}
              type="radio"
              checked={checked}
              onChange={onChange} />
          {label}
      </label>
  );
};

export default Radio;
