export const buildHeaders = (token = null) => {
    token = token || window.localStorage.getItem("token");
    let responseHeader = {
        Accept: "application/json",
        "Content-Type": "application/json",
    };
    if (token !== null) {
        responseHeader["Authorization"] = `Token ${token}`;
    }
    return responseHeader;
    // return {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //     Authorization: `Token ${token}`,
    // };
};

export const calculateSum: number = (list, field) =>
    list.map(item => item[field]).reduce((prev, curr) => prev + curr, 0);
