// @flow
import React, { useContext } from "react";
import Styles from "./index.module.scss";
import logo from "../../assets/FSC-logo.png";
import { Link } from "react-router-dom";
import { logoutAction } from "../../state/actions/user";
import StoreContext from "../../state/context/store";

const Header = () => {
    const [store, dispatch] = useContext(StoreContext);
    const { token } = store.user;

    const logout = () => {
        logoutAction(token, dispatch);
    };

    return (
        <header className={Styles.header}>
            <div className="logo-wrapper">
                <img src={logo} className="logo" alt="Forest Stewardship Council" />
                <span className="logo-site-text">
                    Supplier
                    <br />
                    Portal
                </span>
            </div>
            <span className={Styles["header-links"]}>
                <a className={Styles.tbd} href="mailto:info@us.fsc.org">
                    Get Help From FSC
                </a>
                <Link className={Styles.tbd} to="/change-password">
                    Change Password
                </Link>
                <Link onClick={logout} className={Styles.tbd} to="/">
                    Logout
                </Link>
            </span>
        </header>
    );
};

export default Header;
