// @flow
import config from "../../config";
import type { Dispatch, AnalyticsType } from "../types/analytics";
import type { ResponseErrorType } from "../types/globals";

import { buildHeaders } from "../../lib/helpers";

export const getAnalytics = async (dispatch: Dispatch): Promise<any> => {
    try {
        const headers = buildHeaders();
        const result = await fetch(`${config.apiRoot}/analytics/`, {
            method: "GET",
            headers: headers,
        });
        if (result.status === 400) {
            const json: ResponseErrorType = await result.json();
            if (Array.isArray(json.non_field_errors) && json.non_field_errors.length > 0) {
                return dispatch({
                    type: "API_ERROR",
                    error: json,
                });
            }
            return dispatch({
                type: "API_ERROR",
                error: { nonFieldErrors: ["There was an unknown error"] },
            });
        } else if (Math.floor(result.status / 100) === 5) {
            // TODO 5xx
        } else if (result.status === 200) {
            const json: AnalyticsType = await result.json();
            return dispatch({
                type: "FETCH_ANALYTICS",
                payload: json,
            });
        }
    } catch (err) {
        return dispatch({
            type: "API_ERROR",
            error: { nonFieldErrors: [err.message] },
        });
    }
};
