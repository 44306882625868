// @flow
import React, { useContext } from "react";
import Styles from "./index.module.scss";
import ProductLocationForm from "./product-location-form";
import ProductLocationList from "./product-location-list";
import Button from "../../../components/button";
import { patchUserField } from "../../../state/actions/user";
import StoreContext from "../../../state/context/store";

type Props = { setActiveTab: Function, status: string };

const Products = (props: Props) => {
    const { setActiveTab, status } = props;
    const [store, dispatch] = useContext(StoreContext);
    const { productLocationsReducer } = store;
    const { productLocations } = productLocationsReducer;

    const token = window.localStorage.getItem("token");

    const handleSubmitForVerification = () => {
        patchUserField(token, dispatch, "status", "pending");
    };

    return (
        <div className={Styles.products}>
            <ProductLocationForm className={Styles["add-form"]} />
            <ProductLocationList />

            {status === "unverified" && (
                <>
                    <div className="button-row">
                        <Button
                            gray={true}
                            label="Back"
                            onClick={() => setActiveTab("locations")}
                        />
                        <Button
                            disabled={
                                productLocations.length === 0 ||
                                !store.user.isEmailVerified
                            }
                            label="Finish Company Data Entry"
                            onClick={() => handleSubmitForVerification()}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default Products;
