// @flow
import React, { useContext } from "react";
import Styles from "./index.module.scss";
import Location from "./location";
import StoreContext from "../../../../state/context/store";

type Props = {
    editProductLocationId: null | number,
    setEditProductLocationId: (null | number) => void,
    editLocationId: null | number | "addForm",
    setEditLocationId: (null | number | "addForm") => void,
    firstLocationLocationTypesEmpty: boolean,
};

const LocationList = (props: Props) => {
    const {
        editProductLocationId,
        setEditProductLocationId,
        editLocationId,
        setEditLocationId,
    } = props;
    const [store] = useContext(StoreContext);
    const { locations } = store.locationsReducer;

    return (
        <ul className={Styles["locations-list"]}>
            {locations.map(location => {
                return (
                    <Location
                        location={location}
                        key={location.id}
                        editProductLocationId={editProductLocationId}
                        setEditProductLocationId={setEditProductLocationId}
                        editLocationId={editLocationId}
                        setEditLocationId={setEditLocationId}
                    />
                );
            })}
        </ul>
    );
};

export default LocationList;
