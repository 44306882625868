// @flow
import config from "../../config";

import type {
    Dispatch,
    LocationInitialDataType,
    LocationType,
    LocationsType,
} from "../types/locations";
import type { ResponseErrorType } from "../types/globals";

import { buildHeaders } from "../../lib/helpers";
import { USER_ACTIONS } from "../constants";

export const updateLocation = async (
    dispatch: Dispatch,
    locationFormData: LocationType
): Promise<any> => {
    try {
        const { id, ...formData } = locationFormData;
        const headers = buildHeaders();
        const result = await fetch(`${config.apiRoot}/location/${id}/`, {
            method: "PATCH",
            headers: headers,
            body: JSON.stringify({ ...formData }),
        });
        if (result.status === 400) {
            const json: ResponseErrorType = await result.json();
            return dispatch({
                type: "API_ERROR",
                error: json,
            });

            // if (Array.isArray(json.non_field_errors) && json.non_field_errors.length > 0) {
            //     return dispatch({
            //         type: "API_ERROR",
            //         error: json,
            //     });
            // }
            // return dispatch({
            //     type: "API_ERROR",
            //     error: { nonFieldErrors: ["There was an unknown error"] },
            // });
        } else if (result.status === 401) {
            return dispatch({ type: USER_ACTIONS.LOGOUT_USER });
        } else if (Math.floor(result.status / 100) === 5) {
            console.log("500 error");
            // TODO 5xx
        } else if (result.status === 200) {
            const json: LocationType = await result.json();
            return dispatch({
                type: "UPDATE_LOCATION",
                location: json,
            });
        }
    } catch (err) {
        console.error(err);
        return dispatch({
            type: "API_ERROR",
            error: { nonFieldErrors: [err.message] },
        });
    }
};

export const addLocation = async (
    dispatch: Dispatch,
    locationFormData: LocationInitialDataType
): Promise<any> => {
    try {
        const headers = buildHeaders();
        const result = await fetch(`${config.apiRoot}/location/`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ ...locationFormData }),
        });
        if (result.status === 400) {
            const json: ResponseErrorType = await result.json();
            return dispatch({
                type: "API_ERROR",
                error: json,
            });

            // if (Array.isArray(json.non_field_errors) && json.non_field_errors.length > 0) {
            //     return dispatch({
            //         type: "API_ERROR",
            //         error: json,
            //     });
            // }
            // return dispatch({
            //     type: "API_ERROR",
            //     error: { nonFieldErrors: ["There was an unknown error"] },
            // });
        } else if (result.status === 401) {
            return dispatch({ type: USER_ACTIONS.LOGOUT_USER });
        } else if (Math.floor(result.status / 100) === 5) {
            console.log("5xx error");
            // TODO 5xx
        } else if (result.status === 201) {
            const json: LocationType = await result.json();
            return dispatch({
                type: "UPDATE_LOCATION",
                location: json,
            });
        }
    } catch (err) {
        console.error(err);
        return dispatch({
            type: "API_ERROR",
            error: { nonFieldErrors: [err.message] },
        });
    }
};

export const deleteLocation = async (
    dispatch: Dispatch,
    locationId: number
): Promise<any> => {
    try {
        const headers = buildHeaders();
        // we could delete it from local mem first... but we're not doing that yet.
        const result = await fetch(
            `${config.apiRoot}/location/${locationId}/`,
            {
                method: "DELETE",
                headers: headers,
                body: JSON.stringify({}),
            }
        );
        if (result.status === 400) {
            const json: ResponseErrorType = await result.json();
            if (
                Array.isArray(json.non_field_errors) &&
                json.non_field_errors.length > 0
            ) {
                return dispatch({
                    type: "API_ERROR",
                    error: json,
                });
            }
            return dispatch({
                type: "API_ERROR",
                error: { nonFieldErrors: ["There was an unknown error"] },
            });
        } else if (result.status === 401) {
            return dispatch({ type: USER_ACTIONS.LOGOUT_USER });
        } else if (Math.floor(result.status / 100) === 5) {
            console.log("5xx error");
            // TODO 5xx
        } else if (result.status === 204) {
            return getLocations(dispatch);
        }
    } catch (err) {
        console.error(err);
        return dispatch({
            type: "API_ERROR",
            error: { nonFieldErrors: [err.message] },
        });
    }
};

export const getLocations = async (dispatch: Dispatch): Promise<any> => {
    try {
        const headers = buildHeaders();
        const result = await fetch(`${config.apiRoot}/location/`, {
            method: "GET",
            headers: headers,
        });
        if (result.status === 400) {
            const json: ResponseErrorType = await result.json();
            if (
                Array.isArray(json.non_field_errors) &&
                json.non_field_errors.length > 0
            ) {
                return dispatch({
                    type: "API_ERROR",
                    error: json,
                });
            }
            return dispatch({
                type: "API_ERROR",
                error: { nonFieldErrors: ["There was an unknown error"] },
            });
        } else if (result.status === 401) {
            return dispatch({ type: USER_ACTIONS.LOGOUT_USER });
        } else if (Math.floor(result.status / 100) === 5) {
            console.log("5xx error");
            // TODO 5xx
        } else if (result.status === 200) {
            const json: LocationsType = await result.json();
            return dispatch({
                type: "FETCH_LOCATIONS",
                locations: json,
            });
        }
    } catch (err) {
        console.error(err);
        return dispatch({
            type: "API_ERROR",
            error: { nonFieldErrors: [err.message] },
        });
    }
};
