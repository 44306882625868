import React, { useContext } from "react";
import Styles from "../pending/index.module.scss";
import Button from "../../../components/button";
import { patchUserField, logoutAction } from "../../../state/actions/user";
import StoreContext from "../../../state/context/store";

type Props = { setActiveTab: Function };

const Pending = (props: Props) => {
    const context = useContext(StoreContext);
    const dispatch = context[1];

    const token = window.localStorage.getItem("token");

    const handleWithdrawFromVerification = () => {
        patchUserField(token, dispatch, "status", "unverified");
    };

    return (
        <div className={Styles.pending}>
            <h2>Thanks! FSC will now verify your new information</h2>
            <p>
                Within two business days, your data entries will be verified. On confirmation, they
                will be published. In the meantime, if you have questions, please contact us at{" "}
                <a href={"mailto:info@us.fsc.org"}>info@us.fsc.org</a>.
            </p>
            <p>&nbsp;</p>
            <p>
                You can now click “Log Out”, or just close the browser window. Or, to make further
                changes, please click the “Continue Editing” button.
            </p>
            <br />
            <br />
            <div className="button-row">
                <Button
                    fullWidth={false}
                    label="Continue Editing"
                    onClick={() => handleWithdrawFromVerification()}
                />
                <Button
                    fullWidth={false}
                    label="Log Out"
                    onClick={() => logoutAction(token, dispatch)}
                />
            </div>
        </div>
    );
};

export default Pending;
