// @flow
import React, { useContext, useState } from "react";
import Styles from "./index.module.scss";
import LocationForm from "./location-form";
import LocationList from "./location-list";
import { addLocation } from "../../../state/actions/locations";
import type { LocationInitialDataType } from "../../../state/types/locations";
import Button from "../../../components/button";
import StoreContext from "../../../state/context/store";

type Props = {
    setActiveTab: Function,
    status: string,
    missingLocationTypes: boolean,
    missingSpecies: boolean,
};

const Locations = (props: Props) => {
    const {
        setActiveTab,
        status,
        missingLocationTypes,
        missingSpecies,
    } = props;

    const [store, dispatch] = useContext(StoreContext);
    const { locations } = store.locationsReducer;
    const firstLocationLocationTypesEmpty =
        locations.length === 1 && locations[0].locationTypes.length === 0;
    const initialEditLocationId = () => {
        if (firstLocationLocationTypesEmpty) {
            return locations[0].id;
        } else {
            return null;
        }
    };

    const [editLocationId, setEditLocationId] = useState(initialEditLocationId);
    const [editProductLocationId, setEditProductLocationId] = useState(null);

    const addLocationWrapper = (locationFormData: LocationInitialDataType) => {
        setEditLocationId(null);
        addLocation(dispatch, locationFormData);
    };

    const cancelHandler = () => {
        setEditLocationId(null);
    };

    return (
        <div className={Styles.locations}>
            <div className='profile-header'>
                <h2>Locations</h2>
                <p>
                    Add all company locations that you want to show product
                    availability for. You must add at least one location.
                </p>
            </div>
            <div>
                {editLocationId === "addForm" && (
                    <LocationForm
                        submitHandler={addLocationWrapper}
                        cancelHandler={cancelHandler}
                    />
                )}
                {editLocationId !== "addForm" &&
                    !firstLocationLocationTypesEmpty && (
                        <div className={Styles["add-location-box"]}>
                            <Button
                                label={"Add a Location"}
                                onClick={() => {
                                    setEditLocationId("addForm");
                                }}
                            />
                        </div>
                    )}
            </div>
            <LocationList
                editProductLocationId={editProductLocationId}
                setEditProductLocationId={setEditProductLocationId}
                editLocationId={editLocationId}
                setEditLocationId={setEditLocationId}
            />
            {status === "unverified" && (
                <>
                    <div className='button-row'>
                        <Button
                            gray={true}
                            label='Back'
                            className='gray'
                            onClick={() => setActiveTab("company")}
                        />
                        <Button
                            disabled={
                                locations.length === 0 ||
                                editLocationId ||
                                missingLocationTypes ||
                                missingSpecies
                            }
                            label='Continue'
                            onClick={() => setActiveTab("products")}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default Locations;
