// @flow
import React from "react";
import Styles from "./index.module.scss";

const Checkbox = (props: Object) => {
    const { name, label, onChange, checked } = props;
    return (
        <label className={Styles.checkbox}>
            <input name={name} type="checkbox" checked={checked} onChange={onChange} />
            {label}
        </label>
    );
};

export default Checkbox;
