// @flow
import React, { useContext, useEffect, useState } from "react";
import ProductLocation from "./product-location";
import type { ProductLocationsType } from "../../../../state/types/product-locations";
import { calculateSum } from "../../../../lib/helpers";
import type { SectionsType } from "../../../../state/types/section";
import StoreContext from "../../../../state/context/store";
import Styles from "./index.module.scss";
import { Link } from "react-router-dom";
import ProductLocationForm from "../product-location-form";

const Product = props => {
    const { product } = props;
    const [store] = useContext(StoreContext);
    const { locations } = store.locationsReducer;

    const locationsCount = locations.length;
    const locationsForProductCount = product.locations.length;
    const isMultipleLocationsForProduct = locationsForProductCount > 1;
    const [isVisibleProductLocations, setIsVisibleProductLocations] = useState(false);
    const [showSingleProductLocationForm, setShowSingleProductLocationForm] = useState(false);
    const [showAddProductToLocationForm, setShowAddProductToLocationForm] = useState(false);
    const [showEditAllProductLocationForm, setShowEditAllProductLocationForm] = useState(false);
    const [editProductLocationId, setEditProductLocationId] = useState(null);

    useEffect(() => {
        if (locationsForProductCount === 1) {
            setIsVisibleProductLocations(false);
        }
    }, [locationsForProductCount]);

    return (
        <li
            className={`
                ${Styles["list-item-above-product-header"]}
                ${
                    showSingleProductLocationForm ||
                    showAddProductToLocationForm ||
                    showEditAllProductLocationForm
                        ? Styles["editable"]
                        : ""
                }
                ${locationsCount > 1 ? Styles["multipleLocations"] : ""}
                ${isMultipleLocationsForProduct ? Styles["multipleProductLocations"] : ""}
                ${
                    locationsCount === locationsForProductCount
                        ? Styles["isEquivalentLocationsProductLocations"]
                        : ""
                }
            `}
        >
            <div
                className={Styles["product-header"]}
                onClick={() => {
                    if (locationsCount === 1 && !showSingleProductLocationForm) {
                        setShowSingleProductLocationForm(true);
                    } else if (locationsCount === 1 && showSingleProductLocationForm) {
                        setShowSingleProductLocationForm(false);
                        setShowEditAllProductLocationForm(false);
                        setShowSingleProductLocationForm(false);
                        setShowAddProductToLocationForm(false);
                    } else if (!isVisibleProductLocations) {
                        setIsVisibleProductLocations(true);
                    } else {
                        setIsVisibleProductLocations(false);
                        setShowEditAllProductLocationForm(false);
                        setShowSingleProductLocationForm(false);
                        setShowAddProductToLocationForm(false);
                    }
                }}
            >
                <div className={Styles["product-main"]}>
                    <h4>{product.name}</h4>
                    {locationsCount > 1 && (
                        <span className={Styles["location-count"]}>
                            ({locationsForProductCount} location
                            {isMultipleLocationsForProduct ? "s" : ""})
                        </span>
                    )}
                </div>
                {locationsCount === 1 && !showSingleProductLocationForm && (
                    <div className={Styles["edit-product-actions"]}>
                        <Link to={""} className={Styles["edit-product-button"]}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="26"
                                height="26"
                                viewBox="0 0 26 26"
                            >
                                <path
                                    d="M5.338,19.3L18.724,5.9l5.354,5.36-13.386,13.4ZM22.07,2.546a1.891,1.891,0,0,1,2.677,0l2.677,2.68a1.9,1.9,0,0,1,0,2.68l-2.008,2.01-5.354-5.36ZM1.991,28.009L4,20.638,9.354,26Z"
                                    transform="translate(-2 -2)"
                                />
                            </svg>
                        </Link>
                    </div>
                )}
                {locationsCount > 1 && (
                    <div className={Styles["edit-product-actions"]}>
                        {!isVisibleProductLocations && (
                            <Link
                                onClick={() => {
                                    setIsVisibleProductLocations(true);
                                    // setShowEditAllProductLocationForm(false);
                                }}
                                to={""}
                            >
                                <span className={Styles["plus-minus"]}></span>
                            </Link>
                        )}
                        {isVisibleProductLocations && (
                            <Link
                                // onClick={() => {
                                //     setIsVisibleProductLocations(false);
                                //     setShowEditAllProductLocationForm(false);
                                //     setShowSingleProductLocationForm(false);
                                //     setShowAddProductToLocationForm(false);
                                // }}
                                to={""}
                                className={Styles["collapse"]}
                            >
                                <span className={Styles["plus-minus"]}></span>
                            </Link>
                        )}
                    </div>
                )}
            </div>

            {isVisibleProductLocations && (
                <ul className={Styles["locations"]}>
                    {product.locations.map(location => (
                        <ProductLocation
                            editProductLocationId={editProductLocationId}
                            setEditProductLocationId={setEditProductLocationId}
                            locationName={location.name}
                            productLocation={location.productLocation}
                            key={location.productLocation.id}
                        />
                    ))}
                    {locationsForProductCount > 1 &&
                        !showEditAllProductLocationForm &&
                        !editProductLocationId &&
                        !showAddProductToLocationForm && (
                            <li>
                                <Link
                                    onClick={() => {
                                        setShowEditAllProductLocationForm(true);
                                        setEditProductLocationId(null);
                                        setShowAddProductToLocationForm(false);
                                    }}
                                    to={""}
                                >
                                    Edit All Locations
                                </Link>
                            </li>
                        )}
                    {product.locations.length < locations.length &&
                        !showAddProductToLocationForm &&
                        !editProductLocationId &&
                        !showEditAllProductLocationForm && (
                            <li>
                                <Link
                                    onClick={() => {
                                        setShowAddProductToLocationForm(true);
                                        setShowEditAllProductLocationForm(false);
                                        setEditProductLocationId(null);
                                    }}
                                    to={""}
                                >
                                    Add Location
                                </Link>
                            </li>
                        )}
                </ul>
            )}

            {showSingleProductLocationForm && (
                <div className={Styles["editing-product"]}>
                    <ProductLocationForm
                        className={Styles["editSingleForm"]}
                        productLocationName={product.locations[0].name}
                        productLocation={product.locations[0].productLocation}
                        setShowSingleProductLocationForm={setShowSingleProductLocationForm}
                        showSingleProductLocationForm={showSingleProductLocationForm}
                        setIsVisibleProductLocations={setIsVisibleProductLocations}
                    />
                </div>
            )}
            {showAddProductToLocationForm && (
                <div className={Styles["editing-product"]}>
                    <ProductLocationForm
                        className={Styles["addProductToLocationForm"]}
                        locationName={product.locations[0].name}
                        parentLocation={product.locations[0]}
                        parentProduct={product.id}
                        setShowAddProductToLocationForm={setShowAddProductToLocationForm}
                        showAddProductToLocationForm={showAddProductToLocationForm}
                    />
                </div>
            )}

            {showEditAllProductLocationForm && (
                <div className={Styles["editing-product"]}>
                    <ProductLocationForm
                        className={Styles["editAllLocationsForm"]}
                        parentProduct={product.id}
                        setShowEditAllProductLocationForm={setShowEditAllProductLocationForm}
                        showEditAllProductLocationForm={showEditAllProductLocationForm}
                    />
                </div>
            )}
        </li>
    );
};

const Section = (props: any) => {
    const { section } = props;
    return (
        <li>
            <div className={Styles["section-name"]}>
                <h3>
                    {section.name} ({section.productsCount})
                </h3>
                <span className={Styles["line"]} />
            </div>
            <ul>
                {section.categories.map(category => (
                    <Category category={category} key={category.id} />
                ))}
            </ul>
        </li>
    );
};

const Category = (props: any) => {
    const { category } = props;
    return (
        <li>
            <h4 className={Styles["category-name"]}>{category.name}</h4>
            <ul className={Styles["product-list"]}>
                {category.products.map(product => (
                    <Product product={product} key={product.id} />
                ))}
            </ul>
        </li>
    );
};

const FilteredProducts = (props: any) => {
    const { title, sections } = props;
    return (
        <div className={Styles["product-group"]}>
            <h1>{title}</h1>
            <ul className={Styles["all-products"]}>
                {sections.map(section => {
                    return <Section section={section} key={section.id} />;
                })}
            </ul>
        </div>
    );
};

const getFilteredSections = (sections: SectionsType, productLocations: ProductLocationsType) => {
    let filteredSections: SectionsType = [];
    let includedProducts = [];
    sections.forEach(section => {
        let categories = [];
        section.categories.forEach(category => {
            let products = []; // [{id: 1, name: "Product #1", description: "cool thing to have", locations: []

            category.products.forEach(product => {
                const theseProductLocations = productLocations.filter(productLocation => {
                    return productLocation.product === product.id;
                });
                let theseLocations = []; // [{id: 1, name: "third spot", productLocation: [{}...]]

                theseProductLocations.forEach(productLocation => {
                    theseLocations.push({
                        id: productLocation.location,
                        name: `${productLocation.streetAddress1}`,
                        productLocation: {
                            id: productLocation.id,
                            retail: productLocation.retail,
                            wholesale: productLocation.wholesale,
                            contractor: productLocation.contractor,
                            availability: productLocation.availability,
                            status: productLocation.status,
                        },
                    });
                });
                if (theseLocations.length > 0 && !includedProducts.includes(product.id)) {
                    products.push({
                        id: product.id,
                        name: product.name,
                        description: product.description,
                        locations: theseLocations,
                    });
                    includedProducts.push(product.id);
                }
            });

            if (products.length) {
                categories.push({
                    name: category.name,
                    products: products,
                    id: category.id,
                    productsCount: products.length,
                });
            }
        });
        if (categories.length) {
            const productsCount = calculateSum(categories, "productsCount");
            filteredSections.push({
                name: section.name,
                categories: categories,
                id: section.id,
                productsCount: productsCount,
            });
        }
    });
    return filteredSections;
};

type Props = {};

const ProductLocationList = (props: Props) => {
    const [store] = useContext(StoreContext);
    const { sections, productLocationsReducer } = store;
    const { productLocations } = productLocationsReducer;

    const activeProductLocations: ProductLocationsType =
        productLocations.filter(productList => {
            return productList.status === "available";
        }) || [];
    const inactiveProductLocations: ProductLocationsType =
        productLocations.filter(productList => {
            return productList.status !== "available";
        }) || [];

    const activeSections = getFilteredSections(sections, activeProductLocations);
    const inactiveSections = getFilteredSections(sections, inactiveProductLocations);

    return (
        <>
            {activeSections.length > 0 && (
                <FilteredProducts title={"Available Products"} sections={activeSections} />
            )}
            {inactiveSections.length > 0 && (
                <FilteredProducts title={"Unavailable Products"} sections={inactiveSections} />
            )}
        </>
    );
};

export default ProductLocationList;
