// @flow
import React, { useContext, useState } from "react";
import Styles from "./index.module.scss";
import CompanyInfoTextBox from "../../../components/company-info-textbox";
import StoreContext from "../../../state/context/store";
import Button from "../../../components/button";
import { USER_ACTIONS } from "../../../state/constants";
import { patchUserField, checkCompanyInfo } from "../../../state/actions/user";

type Props = { setActiveTab: Function, status: string };

const Company = (props: Props) => {
    const { setActiveTab, status } = props;
    const [store, dispatch] = useContext(StoreContext);
    const { user } = store;
    const { fscLicenseCode } = user;
    const {
        certifiedSince,
        contactEmail,
        companyName,
        contactName,
        contactPhone,
        webAddress,
        apiError,
        token,
    } = user;
    let currentError = false;
    try {
        currentError =
            Object.keys(apiError).filter((field) => {
                return apiError[field].length > 0;
            }).length > 0;
    } catch (e) {
        if (e instanceof TypeError) {
            return dispatch({ type: USER_ACTIONS.LOGOUT_USER });
        }
    }

    const prePendHTTP = (text: string) => {
        let newText = text;
        if (text.slice(0, 4) !== "http" && text.length > 0) {
            newText = "http://" + text;
        }
        return newText;
    };

    const submitDisabled = () => {
        const checkForValue = (every: boolean, current: string) => {
            if (current === "") {
                return false;
            } else {
                return Boolean(every);
            }
        };

        const requiredFieldsLocallyComplete = [
            contactEmail,
            companyName,
            contactName,
            contactPhone,
            // webAddress,
        ].reduce(checkForValue);

        if (currentError) {
            return true;
        } else {
            return !requiredFieldsLocallyComplete;
        }
    };

    const submitCompanyInfo = async () => {
        const response_json = await checkCompanyInfo(
            contactEmail,
            companyName,
            contactName,
            contactPhone,
            webAddress,
            token,
            dispatch
        );

        if (response_json === undefined) {
            setActiveTab("locations");
        }
        // do nothing if an error was returned, as another field patch will catchup shortly.
    };

    return (
        <div className={Styles.company}>
            <div className='profile-header'>
                <h2>Company Profile</h2>
                <p>
                    <span>
                        Fields marked with
                        <span className='required-asterix'>&nbsp;*</span> are
                        required.
                    </span>
                </p>
                {fscLicenseCode && (
                    <span className={Styles["fsc-code"]}>
                        FSC License Code: {fscLicenseCode}{" "}
                    </span>
                )}
            </div>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                }}
            >
                <fieldset>
                    <div className='form-group'>
                        <h3>Contact Information</h3>
                        <p>
                            The following information is used for both your
                            login credentials and to display publicly as your
                            sales contact.
                        </p>
                        <div className='form-row'>
                            <CompanyInfoTextBox
                                field='contactName'
                                placeholder='Name'
                            />
                            <CompanyInfoTextBox
                                autoComplete='email'
                                field='contactEmail'
                                placeholder='Work Email'
                            />
                            <CompanyInfoTextBox
                                autoComplete='phone'
                                field='contactPhone'
                                placeholder='Phone'
                                label={"Phone"}
                                isRequired={true}
                            />
                        </div>
                    </div>
                    <div className='form-group'>
                        <h3>Company Information</h3>
                        <div className='form-row'>
                            <CompanyInfoTextBox
                                autoComplete='off'
                                field='companyName'
                                placeholder='Company Name'
                            />
                        </div>
                        <div className='form-row'>
                            <CompanyInfoTextBox
                                autoComplete='off'
                                field={"webAddress"}
                                placeholder={"http://www.somesite.com"}
                                label={"Company Website"}
                                infoText={
                                    "If you don’t have a website, consider linking to a social media account or use your certificate url found on https://info.fsc.org."
                                }
                                transform={prePendHTTP}
                                isRequired={false}
                            />
                            {certifiedSince && (
                                <CompanyInfoTextBox
                                    autoComplete='off'
                                    field='certifiedSince'
                                    placeholder='Certified Since'
                                    disabled={true}
                                    infoText={
                                        "This field is maintained by FSC Staff.  If you believe this information is incorrect, please contact FSC at info@us.fsc.org."
                                    }
                                />
                            )}
                        </div>
                    </div>
                </fieldset>
            </form>
            {status === "unverified" && (
                <div className='button-row'>
                    <Button
                        fullWidth={false}
                        disabled={submitDisabled()}
                        label='Continue'
                        onClick={(e) => {
                            e.preventDefault();
                            submitCompanyInfo();
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export default Company;
