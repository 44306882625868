// @flow
import React, { useContext, useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import Styles from "./index.module.scss";
import Box from "../../components/box";
import TextBox from "../../components/textbox";
import Button from "../../components/button";
import { changePasswordAction, resetApiError } from "../../state/actions/user";
import logo from "../../assets/FSC-logo.png";
import StoreContext from "../../state/context/store";

const ChangePassword = withRouter(({ history }) => {
    const [newPassword, setNewPassword] = useState("");
    const [currentPassword, setCurrentPassword] = useState("");
    const [error, setError] = useState({});
    const [store, dispatch] = useContext(StoreContext);
    const { user } = store;
    const { passwordChanged, token, apiError } = user;

    const validate = (): boolean => {
        if (newPassword.length < 8) {
            setError(prevError => {
                return {
                    ...prevError,
                    newPassword: "Your password must be at least 8 characters",
                };
            });
            return false;
        }
        return true;
    };

    const change = () => {
        if (validate()) {
            changePasswordAction(newPassword, currentPassword, token, dispatch);
        }
    };

    useEffect(() => {
        setError(prevError => {
            return { ...apiError, ...prevError };
        });
    }, [apiError]);

    const formFields = ["currentPassword", "newPassword"];
    let nonFieldErrors = Object.keys(error)
        .filter(field => !formFields.includes(field))
        .map(key => {
            return (
                <div className={"error"} key={key}>
                    {error[key]}
                </div>
            );
        });
    const formErrorStyle = { border: "2px solid red" };

    const changePage = (
        <div className={`${Styles.change} box_wrapper`}>
            <div className="logo-wrapper">
                <img src={logo} className="logo" alt="Forest Stewardship Council" />
                <span className="logo-site-text">
                    Supplier
                    <br />
                    Portal
                </span>
            </div>
            <Box className={Styles.change_box}>
                <h1>Change Password</h1>
                <p className="sub-head">Choose a new password.</p>
                <form>
                    <TextBox
                        classSuffix=""
                        type="password"
                        style={error.currentPassword && formErrorStyle}
                        value={currentPassword}
                        onChange={({ target }: SyntheticInputEvent<HTMLInputElement>) => {
                            setCurrentPassword(target.value);
                            setError(prevError => {
                                const { currentPassword, ...remaining } = prevError;
                                return remaining;
                            });
                        }}
                        placeholder="Enter current password"
                    />
                    {error.currentPassword && (
                        <div className={"error"}>{error.currentPassword}</div>
                    )}

                    <TextBox
                        classSuffix=""
                        type="password"
                        style={error.newPassword && formErrorStyle}
                        value={newPassword}
                        onChange={({ target }: SyntheticInputEvent<HTMLInputElement>) => {
                            setNewPassword(target.value);
                            setError(prevError => {
                                const { newPassword, ...remaining } = prevError;
                                return remaining;
                            });
                        }}
                        placeholder="Enter new password"
                    />
                    <div className={"helpText"}>
                        <span>Password must be at least 8 characters and not obvious.</span>
                    </div>
                    {error.newPassword && <div className={"error"}>{error.newPassword}</div>}
                    {nonFieldErrors}
                </form>
                <div className="button-row">
                    <Button
                        outline={true}
                        gray={true}
                        label={"Cancel"}
                        onClick={() => {
                            resetApiError(dispatch);
                            history.push("/");
                        }}
                    />
                    <Button label="Change" onClick={change} />
                </div>
            </Box>
        </div>
    );

    const postChangePage = (
        <div className={`${Styles.change} box_wrapper`}>
            <div className="logo-wrapper">
                <img src={logo} className="logo" alt="Forest Stewardship Council" />
                <span className="logo-site-text">
                    Supplier
                    <br />
                    Portal
                </span>
            </div>
            <Box className={Styles.change_box}>
                <h2>Password changed</h2>
                <div>Your password has been changed.</div>
                <Link to="/">Home</Link>
            </Box>
        </div>
    );

    return passwordChanged ? postChangePage : changePage;
});

export default ChangePassword;
