// @flow
import React from "react";
import Select from "react-select";
import Styles from "./index.module.scss";
import ReactTooltip from "react-tooltip";

type Props = {
    options: Array<{ value: string, label: string }>,
    field: string,
    label: string,
    setter: Function,
    value: string,
    isRequired?: boolean,
    infoText?: string,
    infoHTML?: any,
};
const customStyles = {
    indicatorSeparator: provided => ({
        ...provided,
        height: "30px",
    }),
    control: provided => ({
        ...provided,
        height: "47px",
    }),
    valueContainer: provided => ({
        ...provided,
        height: "100%",
    }),
    input: provided => ({
        overflow: "hidden",
        position: "absolute",
        height: "40px",
        marginTop: "-5px",
    }),
};

const BasicSelect = (props: Props) => {
    const {
        options,
        field,
        label,
        setter,
        value,
        isRequired = false,
        infoText,
        infoHTML,
        ...rest
    } = props;

    let selectOption = {};
    if (value !== "") {
        selectOption = options.find(option => option.value === value);
    }

    return (
        <div className={Styles["dropdown-container"]}>
            <label
                onClick={e => {
                    e.preventDefault();
                }}
            >
                {label}
                {isRequired && <span className={"required-asterix"}>&nbsp;*</span>}
                {Boolean(infoText || infoHTML) && (
                    <button data-tip data-for={`tooltip_` + field} className="info-tooltip">
                        i
                    </button>
                )}
            </label>
            <ReactTooltip
                id={`tooltip_` + field}
                className={"custom-tooltip"}
                type={"info"}
                effect={"float"}
            >
                {infoText || infoHTML}
            </ReactTooltip>

            <Select
                options={options}
                name={field}
                className={field}
                classNamePrefix={"dropdown"}
                value={selectOption}
                onChange={e => {
                    setter(e.value);
                }}
                styles={customStyles}
                defaultValue={selectOption}
                {...rest}
            />
        </div>
    );
};

export default BasicSelect;
