// @flow
import config from "../../config";
import { USER_ACTIONS } from "../constants";
import type { Dispatch } from "../types/user";
import { buildHeaders } from "../../lib/helpers";

export const resetApiError = async (dispatch: Dispatch): Promise<any> => {
    return dispatch({
        type: USER_ACTIONS.RESET_API_ERROR,
    });
};

export const getMe = async (
    token: string,
    dispatch: Dispatch
): Promise<any> => {
    try {
        const headers = buildHeaders(token);
        const result = await fetch(`${config.apiRoot}/me`, {
            method: "GET",
            headers: headers,
        });
        const json: {
            non_field_errors?: Array<string>,
        } = await result.json();
        if (result.status === 400 || result.status === 404) {
            // TODO Property access will fail if non_field_errors is not an array or doesn't exist
            if (json.non_field_errors && json.non_field_errors.length > 0) {
                return dispatch({
                    type: USER_ACTIONS.API_ERROR,
                    error: json.non_field_errors[0],
                });
            }
            return dispatch({
                type: USER_ACTIONS.API_ERROR,
                error: "There was an unknown error",
            });
        } else if (result.status === 401) {
            console.log("forbidden -- possibly bad token!");
            return dispatch({ type: USER_ACTIONS.LOGOUT_USER });
        } else if (Math.floor(result.status / 100) === 5) {
            // TODO 5xx
        } else if (Math.floor(result.status / 100) === 2) {
            return dispatch({
                type: USER_ACTIONS.SET_ME,
                ...json,
            });
        }
    } catch (err) {
        console.error(err);
        return dispatch({
            type: USER_ACTIONS.API_ERROR,
            apiError: { jsError: err.message },
        });
    }
};

export const recaptchaVerifyAction = async (
    recaptchaToken: string,
    dispatch: Dispatch
): Promise<any> => {
    try {
        const url = `${config.apiRoot}/recaptcha/${recaptchaToken}/`;
        const result = await fetch(url, {
            method: "GET",
        });
        let json = {
            serverError:
                "An error occurred. Please contact FSC for assistance.",
        };
        if (Math.floor(result.status / 100) === 4 && result.status !== 404) {
            json = await result.json();
            return dispatch({
                type: USER_ACTIONS.API_ERROR,
                apiError: json,
            });
        } else if (Math.floor(result.status / 100) === 2) {
            json = await result.json();
            return dispatch({
                type: USER_ACTIONS.RECAPTCHA_VERIFY,
                recaptchaVerified: json.recaptchaVerified,
                // recaptchaVerified: false, // for testing
            });
        } else {
            // Probably a 500
            return dispatch({
                type: USER_ACTIONS.API_ERROR,
                apiError: json,
            });
        }
    } catch (err) {
        console.error(err);
        return dispatch({
            type: USER_ACTIONS.API_ERROR,
            apiError: { jsError: err.message },
        });
    }
};

export const populateRegistrationFormByToken = async (
    registrationToken: string,
    dispatch: Dispatch
): Promise<
    { email: string, fscLicenseCode: string } | { serverError: string }
> => {
    let json;
    try {
        const result = await fetch(
            `${config.apiRoot}/users/populate-registration-form/`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    token: registrationToken,
                }),
            }
        );

        if (result.status === 200) {
            json = await result.json();
            return json;
        } else if (result.status === 400) {
            json = await result.json();
            return {
                email: "",
                fscLicenseCode: "",
                nonFieldErrors: json.nonFieldErrors,
            };
        } else {
            return {
                email: "",
                fscLicenseCode: "",
                nonFieldErrors: [
                    "An error has occurred. Please contact FSC staff at info@us.fsc.org",
                ],
            };
        }
    } catch (err) {
        console.error(err);
        return {
            email: "",
            fscLicenseCode: "",
        };
    }
};

export const loginAction = async (
    email: string,
    password: string,
    dispatch: Dispatch
): Promise<any> => {
    let json = {
        serverError: "An error occurred. Please contact FSC for assistance.",
    };
    const lowerCaseEmail = email.toLowerCase();
    try {
        const result = await fetch(`${config.apiRoot}/token/create`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: lowerCaseEmail,
                password,
            }),
        });

        if (result.status === 200) {
            json = await result.json();
            await getMe(json.authToken, dispatch);
            return dispatch({
                type: USER_ACTIONS.LOGIN_USER,
                token: json.authToken,
            });
        } else {
            json = await result.json();
            return json;
        }
    } catch (err) {
        console.error(err);
        return json;
    }
};

export const registerAction = async (
    email: string,
    password: string,
    fscLicenseCode: string,
    registrationToken: string | null,
    dispatch: Dispatch
): Promise<any> => {
    let json = {
        serverError: "An error occurred. Please contact FSC for assistance.",
    };
    try {
        const result = await fetch(`${config.apiRoot}/users/create`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email,
                password,
                fscLicenseCode,
                uniqueId: registrationToken,
            }),
        });

        if (result.status === 201) {
            json = await result.json();
            await getMe(json.authToken, dispatch);
            return dispatch({
                type: USER_ACTIONS.LOGIN_USER,
                token: json.authToken,
            });
        } else {
            json = await result.json();
            return json;
        }
    } catch (err) {
        console.error(err);
        return json;
    }
};

export const activateAccountAction = async (
    uid: string,
    token: string,
    dispatch: Dispatch
): Promise<any> => {
    // console.log("activateAccountAction");

    try {
        const result = await fetch(`${config.apiRoot}/users/activate/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                uid,
                token,
            }),
        });

        let json = {
            serverError:
                "An error occurred. Please contact FSC for assistance.",
        };
        const status_code = result.status;
        if (Math.floor(status_code / 100) >= 4) {
            return dispatch({
                type: USER_ACTIONS.API_ERROR,
                apiError: json,
            });
        } else if (Math.floor(status_code / 100) === 2) {
            // 200
            json = await result.json();
            // console.log(json);
            await getMe(json.authToken, dispatch);
            return dispatch({
                type: USER_ACTIONS.LOGIN_USER,
                token: json.authToken,
            });
        } else {
            // Probably a 500
            return dispatch({
                type: USER_ACTIONS.API_ERROR,
                apiError: json,
            });
        }
    } catch (err) {
        console.error(err + " got caught");
        return dispatch({
            type: USER_ACTIONS.API_ERROR,
            apiError: { jsError: err.message },
        });
    }
};

export const resetPasswordAction = async (
    email: string,
    dispatch: Dispatch
): Promise<any> => {
    let json = {
        serverError: "An error occurred. Please contact FSC for assistance.",
    };
    try {
        const result = await fetch(`${config.apiRoot}/password/reset/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email,
            }),
        });

        if (result.status === 204) {
            return dispatch({
                type: USER_ACTIONS.PASSWORD_RESET,
            });
        } else {
            json = await result.json();
            return json;
        }
    } catch (err) {
        console.error(err);
        return json;
    }
};

export const changePasswordAction = async (
    newPassword: string,
    currentPassword: string,
    token: string,
    dispatch: Dispatch
): Promise<any> => {
    try {
        const result = await fetch(`${config.apiRoot}/password/`, {
            method: "POST",
            headers: buildHeaders(token),
            body: JSON.stringify({
                new_password: newPassword,
                current_password: currentPassword,
            }),
        });

        let json = {
            serverError:
                "An error occurred. Please contact FSC for assistance.",
        };

        if (result.status === 400) {
            json = await result.json();
            return dispatch({
                type: USER_ACTIONS.API_ERROR,
                apiError: json,
            });
        } else if (Math.floor(result.status / 100) === 2) {
            // 204
            return dispatch({
                type: USER_ACTIONS.PASSWORD_CHANGE,
            });
        } else if (result.status === 401) {
            return dispatch({ type: USER_ACTIONS.LOGOUT_USER });
        } else {
            // Probably a 500
            return dispatch({
                type: USER_ACTIONS.API_ERROR,
                apiError: json,
            });
        }
    } catch (err) {
        console.error(err + " got caught");
        return dispatch({
            type: USER_ACTIONS.API_ERROR,
            apiError: { jsError: err.message },
        });
    }
};

export const logoutAction = async (
    token: string,
    dispatch: Dispatch
): Promise<any> => {
    try {
        const result = await fetch(`${config.apiRoot}/token/destroy/`, {
            method: "POST",
            headers: buildHeaders(token),
        });

        if (result.status !== 204) {
            console.error("Unable to destroy token at logout");
        }
        return dispatch({
            type: USER_ACTIONS.LOGOUT_USER,
        });
    } catch (err) {
        console.error(err + " when trying to destroy token");
        return dispatch({
            type: USER_ACTIONS.LOGOUT_USER,
        });
    }
};

export const confirmPasswordAction = async (
    newPassword: string,
    uid: string,
    token: string,
    dispatch: Dispatch
): Promise<any> => {
    try {
        const result = await fetch(
            `${config.apiRoot}/password/reset/confirm/`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    new_password: newPassword,
                    uid,
                    token,
                }),
            }
        );

        let json = {
            serverError:
                "An error occurred. Please contact FSC for assistance.",
        };

        if (result.status === 400) {
            json = await result.json();
            return dispatch({
                type: USER_ACTIONS.API_ERROR,
                apiError: json,
            });
        } else if (Math.floor(result.status / 100) === 2) {
            // 204
            return dispatch({
                type: USER_ACTIONS.PASSWORD_RESET_CONFIRM,
            });
        } else {
            // Probably a 500
            return dispatch({
                type: USER_ACTIONS.API_ERROR,
                apiError: json,
            });
        }
    } catch (err) {
        console.error(err + " got caught");
        return dispatch({
            type: USER_ACTIONS.API_ERROR,
            apiError: { jsError: err.message },
        });
    }
};

export const updateUserField = (
    dispatch: Dispatch,
    field: string,
    value: string
): void => {
    return dispatch({
        type: USER_ACTIONS.UPDATE_USER_FIELD,
        field,
        value,
    });
};

export const patchUserField = async (
    token: string,
    dispatch: Dispatch,
    field: string,
    value: string
): Promise<any> => {
    try {
        dispatch({
            type: USER_ACTIONS.UPDATE_USER_FIELD,
            field,
            value,
        });
        const result = await fetch(`${config.apiRoot}/me/`, {
            method: "PATCH",
            headers: buildHeaders(token),
            body: JSON.stringify({
                [field]: value,
            }),
        });

        const json = await result.json();
        if (result.status === 400) {
            return dispatch({
                type: USER_ACTIONS.API_ERROR,
                apiError: json,
            });
        } else if (result.status === 200) {
            return dispatch({
                type: USER_ACTIONS.UPDATE_USER_FIELD,
                field,
                value,
                companyInfoCompleted: json.companyInfoCompleted,
            });

            // this version reverts to non-blanks and give a surprising result to user with previously blank fields
            // return dispatch({
            //     type: USER_ACTIONS.SET_ME,
            //     ...json
            // });
            // the following block doesn't update companyInfoCompleted
            // dispatch({
            //     type: USER_ACTIONS.UPDATE_USER_FIELD,
            //     field: [field],
            //     value: value,
            // });
        } else if (result.status === 401) {
            return dispatch({ type: USER_ACTIONS.LOGOUT_USER });
        } else {
            // Probably a 500
            return dispatch({
                type: USER_ACTIONS.API_ERROR,
                apiError: json,
            });
        }
    } catch (err) {
        return dispatch({
            type: USER_ACTIONS.API_ERROR,
            apiError: { jsError: err.message },
        });
    }
};

export const setToken = (token: string, dispatch: Dispatch) => {
    return dispatch({
        type: USER_ACTIONS.SET_TOKEN,
        token: token,
    });
};

export const checkCompanyInfo = async (
    contactEmail: string,
    companyName: string,
    contactName: string,
    contactPhone: string,
    webAddress: string,
    token: string,
    dispatch: Dispatch
): Promise<any> => {
    let json = {
        serverError: "An error occurred. Please contact FSC for assistance.",
    };
    try {
        const result = await fetch(`${config.apiRoot}/me/`, {
            method: "PATCH",
            headers: buildHeaders(token),
            body: JSON.stringify({
                contactEmail,
                companyName,
                contactName,
                contactPhone,
                webAddress,
            }),
        });
        json = await result.json();
        if (result.status === 200) {
            // everything's OK. let it be.
        } else {
            // we've got an error
            return json;
        }
    } catch (err) {
        console.error(err);
        return json;
    }
};
