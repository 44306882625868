// @flow
import React, { useContext, useEffect, useState } from "react";
import Styles from "./index.module.scss";
import Box from "../../components/box";
import Button from "../../components/button";
import TextBox from "../../components/textbox";
import { confirmPasswordAction, resetApiError } from "../../state/actions/user";
import StoreContext from "../../state/context/store";
import logo from "../../assets/FSC-logo.png";
import { Link } from "react-router-dom";

type Props = { match: any };

const ConfirmPassword = (props: Props) => {
    const [store, dispatch] = useContext(StoreContext);
    const { user } = store;
    const { confirmedNewPassword, apiError } = user;
    const [new_password, setNewPassword] = useState("");
    let [error, setError] = useState({});

    useEffect(() => {
        setError(prevError => {
            return { ...prevError, ...apiError };
        });
    }, [apiError]);

    const uid = props.match.params.uid;
    const token = props.match.params.token;

    const validate = (): boolean => {
        let valid = true;
        if (new_password.length === 0) {
            setError(prevError => {
                return {
                    ...prevError,
                    new_password: "You must enter your Password",
                };
            });
            valid = false;
        }
        return valid;
    };

    const confirm = (e: SyntheticEvent<HTMLElement>) => {
        setError({});

        if (validate()) {
            e.preventDefault();
            confirmPasswordAction(new_password, uid, token, dispatch);
        }
    };

    const postConfirmPage = (
        <div className={`${Styles.reset} box_wrapper`}>
            <div className="logo-wrapper">
                <img
                    src={logo}
                    className="logo"
                    alt="Forest Stewardship Council"
                />
                <span className="logo-site-text">
                    Supplier
                    <br />
                    Portal
                </span>
            </div>
            <Box className={Styles.reset_box}>
                <h1>Password Changed</h1>
                <span>Your password has been updated.</span>
                <br />
                <span>
                    You may now <Link to="/">Log In</Link>.
                </span>
            </Box>
        </div>
    );

    const formFields = ["new_password"];
    const nonFieldErrors = Object.keys(error)
        .filter(field => !formFields.includes(field))
        .map(key => {
            return <div className="error">{error[key]}</div>;
        });
    const formErrorStyle = { border: "2px solid red" };

    const confirmPage = (
        <div className={`${Styles.reset} box_wrapper`}>
            <div className="logo-wrapper">
                <img
                    src={logo}
                    className="logo"
                    alt="Forest Stewardship Council"
                />
                <span className="logo-site-text">
                    Supplier
                    <br />
                    Portal
                </span>
            </div>
            <Box className={Styles.reset_box}>
                <h1>Choose a new Password</h1>
                <form>
                    <TextBox
                        autoComplete="off"
                        style={error.new_password && formErrorStyle}
                        value={new_password}
                        type="password"
                        onChange={({
                            target,
                        }: SyntheticInputEvent<HTMLInputElement>) => {
                            setNewPassword(target.value);
                            setError(prevError => {
                                const {
                                    new_password,
                                    ...remaining
                                } = prevError;
                                return remaining;
                            });
                        }}
                        // autoComplete="current-password"
                        placeholder="New Password"
                    />
                    <div className={"helpText"}>
                        <span>
                            Password must be at least 8 characters and not
                            obvious.
                        </span>
                    </div>
                    {error.new_password && (
                        <div className="error">{error.new_password}</div>
                    )}
                    {nonFieldErrors}
                </form>

                <div className="button-row">
                    <Button
                        outline={true}
                        gray={true}
                        label={"Cancel"}
                        onClick={() => {
                            resetApiError(dispatch);
                            props.history.push("/");
                        }}
                    />
                    <Button label="Set Password" onClick={confirm} />
                </div>
            </Box>
        </div>
    );

    return confirmedNewPassword ? postConfirmPage : confirmPage;
};

export default ConfirmPassword;
